import { SvgProps } from "./types";

const DeleteIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.8334 7.5V15.8333H7.16669V7.5H13.8334ZM12.5834 2.5H8.41669L7.58335 3.33333H4.66669V5H16.3334V3.33333H13.4167L12.5834 2.5ZM15.5 5.83333H5.50002V15.8333C5.50002 16.75 6.25002 17.5 7.16669 17.5H13.8334C14.75 17.5 15.5 16.75 15.5 15.8333V5.83333Z"
        fill={props.fill || "#536283"}
      />
    </svg>
  );
};

export default DeleteIcon;
