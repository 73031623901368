import { PayloadAction } from "@reduxjs/toolkit";
import { DELETE_CHANGE_LOG, GET_CHANGE_LOG_DETAIL, GET_CHANGE_LOG_LIST, GET_REVIEW_CHANGE_LOG, GET_REVIEW_SOURCE_CHANGE_LOG } from "../../actionType";

interface changeLogReducerType {
    changeLogList: Array<any>;
    changeLogTotal: number;
    updateChangeLogList: boolean;
    changeLogDetail: any;
    changeLogId: any;
    reviewSourceChangeLog: any;
    reviewChangeLog: any;
}

const initialState: changeLogReducerType = {
    changeLogList: [],
    changeLogTotal: 0,
    updateChangeLogList: false,
    changeLogDetail: {},
    changeLogId: null,
    reviewSourceChangeLog: {},
    reviewChangeLog: {}
}

const changeLogReducer = (state = initialState, action: PayloadAction<any>): changeLogReducerType => {
    switch (action.type) {
        case GET_CHANGE_LOG_LIST:
            return {
                ...state,
                changeLogList: action.payload.results,
                changeLogTotal: action.payload.count,
            }
        case DELETE_CHANGE_LOG:
            return {
                ...state,
                updateChangeLogList: !state.updateChangeLogList,
            }
        case GET_CHANGE_LOG_DETAIL:
            return {
                ...state,
                changeLogDetail: action.payload,
            }
        case GET_REVIEW_SOURCE_CHANGE_LOG:
            if(action.payload.firstLoad){
                return {
                    ...state,
                    reviewSourceChangeLog: action.payload,
                };
            }

            const results = [...state.reviewSourceChangeLog.results??[], ...action.payload.results];

            const newReviewSourceChangeLog = {
                ...action.payload,
                results: [...results]
            };

            return {
                ...state,
                reviewSourceChangeLog: newReviewSourceChangeLog,
            }
        case GET_REVIEW_CHANGE_LOG:
            if(action.payload.firstLoad){
                return {
                    ...state,
                    reviewChangeLog: action.payload,
                };
            }

            const resultsReview = [...state.reviewChangeLog.results??[], ...action.payload.results];

            const newReviewChangeLog = {
                ...action.payload,
                results: [...resultsReview]
            };

            return {
                ...state,
                reviewChangeLog: newReviewChangeLog,
            }
        // case CLOSE_ADD_OR_EDIT_USER_MODAL:
        //     return {
        //         ...state,
        //         userDetail: {},
        //         updateUserList: !state.updateUserList,
        //     }
        default:
            return state;
    }
}

export default changeLogReducer;