import {LinearProgress, linearProgressClasses, styled} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 50,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 50,
        // backgroundColor: theme.palette.mode === 'light' ? 'linear-gradient(180deg, rgba(109, 210, 157, 0), rgba(56, 160, 106, 1))' : '#308fe8',
        backgroundImage: 'linear-gradient(180deg, rgba(109, 210, 157, 0), rgba(56, 160, 106, 1))',
    },
}));

export default BorderLinearProgress;