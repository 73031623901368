import {SvgProps} from "./types";

const GlobeIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M21.4087 8.63017C21.4087 8.63017 21.4087 8.63017 21.4087 8.58002C20.7027 6.65062 19.4216 4.98482 17.7389 3.80797C16.0561 2.63111 14.0528 2 12 2C9.94718 2 7.94389 2.63111 6.26112 3.80797C4.57836 4.98482 3.29733 6.65062 2.59133 8.58002C2.59133 8.58002 2.59133 8.58002 2.59133 8.63017C1.80289 10.8074 1.80289 13.1926 2.59133 15.3698C2.59133 15.3698 2.59133 15.3698 2.59133 15.42C3.29733 17.3494 4.57836 19.0152 6.26112 20.192C7.94389 21.3689 9.94718 22 12 22C14.0528 22 16.0561 21.3689 17.7389 20.192C19.4216 19.0152 20.7027 17.3494 21.4087 15.42C21.4087 15.42 21.4087 15.42 21.4087 15.3698C22.1971 13.1926 22.1971 10.8074 21.4087 8.63017ZM4.22457 14.0059C3.87711 12.6912 3.87711 11.3088 4.22457 9.99415H6.08827C5.92798 11.3266 5.92798 12.6734 6.08827 14.0059H4.22457ZM5.0462 16.0117H6.44898C6.68417 16.9062 7.02033 17.7709 7.45097 18.5892C6.46832 17.9192 5.64689 17.0388 5.0462 16.0117ZM6.44898 7.9883H5.0462C5.63825 6.9644 6.44918 6.08421 7.42091 5.41078C7.00063 6.23041 6.67457 7.09511 6.44898 7.9883ZM10.978 19.7225C9.74729 18.8186 8.88296 17.5013 8.54314 16.0117H10.978V19.7225ZM10.978 14.0059H8.11229C7.9253 12.6752 7.9253 11.3248 8.11229 9.99415H10.978V14.0059ZM10.978 7.9883H8.54314C8.88296 6.49872 9.74729 5.18143 10.978 4.27747V7.9883ZM18.9137 7.9883H17.5109C17.2758 7.09385 16.9396 6.2291 16.5089 5.41078C17.4916 6.0808 18.313 6.96124 18.9137 7.9883ZM12.9819 4.27747C14.2126 5.18143 15.077 6.49872 15.4168 7.9883H12.9819V4.27747ZM12.9819 19.7225V16.0117H15.4168C15.077 17.5013 14.2126 18.8186 12.9819 19.7225ZM15.8476 14.0059H12.9819V9.99415H15.8476C16.0346 11.3248 16.0346 12.6752 15.8476 14.0059ZM16.539 18.5892C16.9696 17.7709 17.3058 16.9062 17.541 16.0117H18.9438C18.3431 17.0388 17.5217 17.9192 16.539 18.5892ZM19.7353 14.0059H17.8717C17.9531 13.3404 17.9933 12.6705 17.9919 12C17.993 11.3295 17.9528 10.6597 17.8717 9.99415H19.7353C20.0828 11.3088 20.0828 12.6912 19.7353 14.0059Z"
        fill={props.fill || "#333333"}
      />
    </svg>
  );
};

export default GlobeIcon;
