import { useEffect } from "react";

const PaymentRedirectGlobal = (userData: any) => {
  useEffect(() => {
   const timer = setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_WEB_CLIENT_URL}/payment?id=${userData.userData.company ? userData.userData.company : userData.userData.companies[0].id}`;
    }, 2000);
	return () => clearTimeout(timer);

  }, []);

  return <></>;
};

export default PaymentRedirectGlobal;
