import {
    CLOSE_ASSGIN_USER_MODAL,
    CLOSE_DELETE_MODAL,
    OPEN_ASSGIN_USER_MODAL,
    OPEN_DELETE_MODAL,
    CLOSE_ADD_OR_EDIT_USER_MODAL,
    OPEN_ADD_OR_EDIT_USER_MODAL,
    SET_USER_PROGRESS, SET_PROFILE_PROGRESS, SET_WEBSITE_PROGRESS, OPEN_PAYMENT_DETAIL_MODAL,
    CLOSE_PAYMENT_DETAIL_MODAL,
    OPEN_REVIEW_SOURCE_DETAIL_MODAL,
    CLOSE_REVIEW_SOURCE_DETAIL_MODAL,
    OPEN_REVIEW_TEXT_DETAIL_MODAL,
    CLOSE_REVIEW_TEXT_DETAIL_MODAL
} from "../../actionType";

export const openAssginUserModal = (data: any) => {
    return {type: OPEN_ASSGIN_USER_MODAL, payload: data}
}

export const closeAssginUserModal = () => {
    return {type: CLOSE_ASSGIN_USER_MODAL}
}

export const closeDeleteModal = () => {
    return {type: CLOSE_DELETE_MODAL}
}

export const openDeleteModal = (data: any, type: number) => {
    return {type: OPEN_DELETE_MODAL, payload: {data: data, type: type}}
}

export const openAddOrEditUserModal = (data: any, type: number) => {
    return {type: OPEN_ADD_OR_EDIT_USER_MODAL, payload: {data: data, type: type}}
}

export const closeAddOrEditUserModal = () => {
    return {type: CLOSE_ADD_OR_EDIT_USER_MODAL}
}
export const openPaymentDetailModal = (data: any) => {
    return {type: OPEN_PAYMENT_DETAIL_MODAL, payload: data}
}

export const closePaymentDetailModal = () => {
    return {type: CLOSE_PAYMENT_DETAIL_MODAL}
}

export const setUserProgress = (data: number) => {
    return {type: SET_USER_PROGRESS, payload: data}
}

export const setProfileProgress = (data: number) => {
    return {type: SET_PROFILE_PROGRESS, payload: data}
}

export const setWebsiteProgress = (data: number) => {
    return {type: SET_WEBSITE_PROGRESS, payload: data}
}

export const openReviewSourceDetailModal = (data: {title: string}) => {
    return {type: OPEN_REVIEW_SOURCE_DETAIL_MODAL, payload: data}
}

export const closeReviewSourceDetailModal = () => {
    return {type: CLOSE_REVIEW_SOURCE_DETAIL_MODAL}
}

export const openReviewTextDetailModal = (data: {title: string}) => {
    return {type: OPEN_REVIEW_TEXT_DETAIL_MODAL, payload: data}
}

export const closeReviewTextDetailModal = () => {
    return {type: CLOSE_REVIEW_TEXT_DETAIL_MODAL}
}