import SignUpModalStyle from "../component/SignUpModalStyle";
import {Grid, styled, Typography} from "@mui/material";
import LinearProgressBar from "../../ProgressBar/LinearProgressBar";
import {useEffect, useState} from "react";

const Container = styled(Grid)({
    margin: "auto",
    maxWidth: 630,
    maxHeight: 300,
})

type PropsType = {
    isOpen: boolean,
    isSuccess?: boolean,
    isLoading?: boolean,
    onClose?: any,
    openInfoModal?: any,
}

const SignUpProgressModal = (props: PropsType) => {
    const [user, setUser] = useState(0);
    const [website, setWebsite] = useState(0);
    const [profile, setProfile] = useState(0);
    const {isOpen, isSuccess, onClose, isLoading, openInfoModal} = props;
    const [timer, setTimer] = useState<any>();

    useEffect(() => {
        if (isOpen) {
            setTimer(() => {
                return setInterval(() => {
                    setUser((prev) => {
                        if (prev === 100) {
                            return 100;
                        }
                        const diff = Math.random() * 10;
                        return Math.min(prev + diff, 100);
                    })
                    setWebsite((prev) => {
                        if (prev === 100) {
                            return 100;
                        }
                        const diff = Math.random() * 10;
                        return Math.min(prev + diff, 100);
                    })
                    setProfile((prev) => {
                        if (prev === 100) {
                            return 100;
                        }
                        const diff = Math.random() * 10;
                        return Math.min(prev + diff, 100);
                    })
                }, 500);
            })
        } else {
            setTimeout(() => {
                clearInterval(timer);
                setUser(0);
                setProfile(0);
                setWebsite(0);
            }, 100)
        }
    }, [isOpen])

    useEffect(() => {
        if (!isLoading && isOpen) {
            setTimeout(() => {
                setUser(100);
                setProfile(100);
                setWebsite(100);
                setTimeout(() => {
                    onClose(false);
                    if (isSuccess) {
                        openInfoModal();
                    }
                }, 500)
            }, 2000)
            // setUser(100);
            // setProfile(100);
            // setWebsite(100);
            // setTimeout(() => {
            //     onClose(false);
            //     if (isSuccess) {
            //         openInfoModal();
            //     }
            // }, 500)
        }
    }, [isLoading, isOpen])

    return (
        <SignUpModalStyle open={isOpen}>
            <Container container spacing={2}>
                <Grid item xs={12} style={{marginBottom: 40}}>
                    <Typography variant={"h2"} style={{textAlign: "center"}}>
                        We are setting everything up
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"body2"} style={{fontWeight: 400}}>
                        User registration
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <LinearProgressBar value={user} variant={"determinate"}/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"body2"} style={{fontWeight: 400}}>
                        Website check
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <LinearProgressBar value={website} variant={"determinate"}/>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"body2"} style={{fontWeight: 400}}>
                        Profile setup
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <LinearProgressBar value={profile} variant={"determinate"}/>
                </Grid>
            </Container>
        </SignUpModalStyle>
    )
}

export default SignUpProgressModal
