import {PayloadAction} from "@reduxjs/toolkit";
import {
    LOGIN,
    LOGIN_ERROR,
    LOADING,
    LOGOUT,
    REGISTER,
    REGISTER_ERROR,
    REGISTER_ERROR_IP,
    ACCOUNT_NOT_FOUND,
    LOGIN_WRONG_INFO,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAIL,
    VALIDATE_TOKEN_INVALID,
    CONFIRM_RESET_PASSWORD,
    CHANGE_PASSWORD,
    UPDATE_ACCOUNT_SETTING, GET_ACCOUNT_DETAIL,
	UPDATE_USERDATA,
} from "../../actionType";
import {User} from "../../../interface/User";
import {
    ACCESS_TOKEN,
    USER,
    REFRESH_TOKEN,
    CLEAR_REDUX,
    FAIL_LOGIN,
} from "../../../constant";
import Cookies from "js-cookie";

type AuthReducerType = {
    isLoading: boolean;
    userData: User;
    access: string;
    refresh: string;
    isSuccess: boolean; //register or login, logout success,
    failLogin: number;
    forgotPasswordFail: boolean;
    forgotPasswordMessage: string;
    lockForgotButton: boolean;
    validateResetToken: boolean;
    resetSuccess: boolean;
    isCheckUserData: boolean;
    isOpenModalError: boolean;
    messageErrorIp: string;
};

const initialState: AuthReducerType = {
    isLoading: false,
    userData: JSON.parse(Cookies.get(USER) || "{}"),
    access: Cookies.get(ACCESS_TOKEN) || "",
    refresh: Cookies.get(REFRESH_TOKEN) || "",
    isSuccess: false,
    failLogin: JSON.parse(localStorage.getItem(FAIL_LOGIN)!),
    forgotPasswordFail: false,
    forgotPasswordMessage: "",
    lockForgotButton: false,
    validateResetToken: true,
    resetSuccess: false,
    isCheckUserData: false,
    isOpenModalError: false,
    messageErrorIp: "",
};

export type UserType = typeof initialState;

const authReducer = (
    state = initialState,
    action: PayloadAction<any>
): AuthReducerType => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoading: false,
                userData: action.payload.userData,
                access: action.payload.login.access,
                refresh: action.payload.login.refresh,
                isSuccess: true,
            };
		case UPDATE_USERDATA: 
		return {
			...state,
			userData: action.payload.userData,
            isCheckUserData: true,
		};
        case ACCOUNT_NOT_FOUND:
            return {
                ...state,
                isLoading: false,
            };
        case LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_ACCOUNT_SETTING:
            return {
                ...state,
                isLoading: false,
            };
        case LOGIN_WRONG_INFO:
            return {
                ...state,
                failLogin: action.payload.failLogin,
                isLoading: false,
            };
        case REGISTER:
            return {
                ...state,
                userData: action.payload,
                isSuccess: true,
                isLoading: false,
            };
        case REGISTER_ERROR:
            return {
                ...state,
                isLoading: false,
            };
            case REGISTER_ERROR_IP:
                return {
                    ...state,
                    isLoading: false,
                    isOpenModalError: !state.isOpenModalError,
                    messageErrorIp: action.payload ?? "",
                };
        case LOGOUT:
            return {
                ...initialState,
                userData: {},
                access: "",
                refresh: "",
                failLogin: 0,
            };
        case CLEAR_REDUX:
            return {
                ...state,
                userData: {},
                access: "",
                refresh: "",
                isSuccess: false,
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                lockForgotButton: true,
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                forgotPasswordFail: true,
                forgotPasswordMessage: action.payload,
            };
        case VALIDATE_TOKEN_INVALID:
            return {
                ...state,
                validateResetToken: false,
            };
        case CONFIRM_RESET_PASSWORD:
            return {
                ...state,
                resetSuccess: true,
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                userData: {},
                access: "",
                refresh: "",
            };
        case GET_ACCOUNT_DETAIL:
            return {
                ...state,
                userData: action.payload,
            }
        default:
            return state;
    }
};

export default authReducer;
