const AccountIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 0.333252C3.32004 0.333252 0.333374 3.31992 0.333374 6.99992C0.333374 10.6799 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7.00004 0.333252ZM7.34012 5.65992C7.34012 4.55992 6.44012 3.65992 5.34012 3.65992C4.24012 3.65992 3.34012 4.55992 3.34012 5.65992C3.34012 6.75992 4.24012 7.65992 5.34012 7.65992C6.44012 7.65992 7.34012 6.75992 7.34012 5.65992ZM5.34012 6.32659C4.97345 6.32659 4.67345 6.02659 4.67345 5.65992C4.67345 5.29325 4.97345 4.99325 5.34012 4.99325C5.70679 4.99325 6.00679 5.29325 6.00679 5.65992C6.00679 6.02659 5.70679 6.32659 5.34012 6.32659ZM11.0068 6.32659C11.0068 7.06659 10.4134 7.65992 9.67342 7.65992C8.93342 7.65992 8.34009 7.06659 8.34009 6.32659C8.33342 5.58659 8.93342 4.99325 9.67342 4.99325C10.4134 4.99325 11.0068 5.58659 11.0068 6.32659ZM5.34004 9.66659C4.41337 9.66659 3.35337 10.0466 2.90004 10.4066C3.81337 11.5066 5.16004 12.2266 6.67337 12.3133V10.4599C6.67337 9.19992 8.66004 8.65992 9.67337 8.65992C10.26 8.65992 11.1667 8.84659 11.8334 9.23992C12.1534 8.55326 12.3334 7.79326 12.3334 6.99326C12.3334 4.05326 9.94004 1.65992 7.00004 1.65992C4.06004 1.65992 1.66671 4.05326 1.66671 6.99326C1.66671 7.81326 1.85337 8.58659 2.18671 9.27992C3.08004 8.62659 4.47337 8.32659 5.34004 8.32659C5.63337 8.32659 5.98671 8.36659 6.36004 8.43326C5.94004 8.81326 5.65337 9.24659 5.49337 9.67326C5.46582 9.67326 5.43826 9.67148 5.41162 9.66976C5.38671 9.66815 5.3626 9.66659 5.34004 9.66659Z"
        fill="#333333"
      />
    </svg>
  );
};

export default AccountIcon;
