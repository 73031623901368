import {SvgProps} from "./types";

const DownIcon: React.FC<SvgProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M12 16L18 10L16.59 8.59L12 13.17L7.41 8.59L6 10L12 16Z"
                fill={props.fill || "#333333"}
            />
        </svg>
    );
};

export default DownIcon;
