import React from "react";

const Company = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_201_144)">
        <path
          d="M6.08496 11.9875H9.89716V12.8803H6.08496V11.9875Z"
          fill="white"
        />
        <path
          d="M6.08496 14.4399H9.89716V15.3352H6.08496V14.4399Z"
          fill="white"
        />
        <path
          d="M6.08496 16.968H9.89716V17.8633H6.08496V16.968Z"
          fill="white"
        />
        <path
          d="M6.08496 19.4982H9.89716V20.3935H6.08496V19.4982Z"
          fill="white"
        />
        <path
          d="M12.6582 7.12467H18.7524V8.01994H12.6582V7.12467Z"
          fill="white"
        />
        <path
          d="M12.6582 10.0663H18.7524V10.9616H12.6582V10.0663Z"
          fill="white"
        />
        <path d="M12.6582 13.0047H18.7524V13.9H12.6582V13.0047Z" fill="white" />
        <path
          d="M20.6062 22.1208V3.80179H16.8986V2.02341H10.8069V3.80179V4.69706V7.58966H5.4523V9.36561H4.28455V22.1208H0.523438V23.0161H4.28455H10.8069H11.7022H13.6168H17.5579H20.6087H24.3649V22.1208H20.6062ZM11.7022 2.91625H16.0058V3.79936H11.7022V2.91625ZM6.34514 8.48007H10.6488V9.36318H6.34514V8.48007ZM5.17739 22.1208V10.2585H5.4523H10.8069V22.1208H5.17739ZM14.5096 22.1208V19.4593H16.6627V22.1208H14.5096ZM17.5555 22.1208V18.5665H13.6144V22.1208H11.6997V9.36318V4.69463H16.8962H19.7085V22.1208H17.5555Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_144">
          <rect
            width="24.328"
            height="24.328"
            fill="white"
            transform="translate(0.280273 0.35466)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Company;
