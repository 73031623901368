import {PayloadAction} from "@reduxjs/toolkit";
import {GET_SOURCE_SOCIAL} from "../../actionType";
import {ISocialSource} from "../../../interface/ISocial";

interface sourceSocialReducerType {
    sourceSocialList: Array<ISocialSource>,
}

const initialState: sourceSocialReducerType = {
    sourceSocialList: [],
}

const sourceReviewReducer = (state = initialState, action: PayloadAction<any>): sourceSocialReducerType => {
    switch (action.type) {
        case GET_SOURCE_SOCIAL:
            return {
                ...state,
                sourceSocialList: action.payload,
            }
        default:
            return state;
    }
}

export default sourceReviewReducer;