import { Dispatch } from "redux";
import { getPlatformList } from "../api/platforms";
import { message } from "antd";
import { GET_PLATFORM_LIST } from "../../actionType";

export const handleGetPlatformList = (data?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await getPlatformList(data);
      if (res?.status === 200) {
        dispatch({ type: GET_PLATFORM_LIST, payload: res?.data });
        return;
      }
      throw new Error("Something is wrong");
    } catch (e) {
      message.error("Something is wrong!");
    }
  };
};
