import styled from "styled-components";
import mediaQueries from "../../utility/responsive";

export const AuthPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/backgrounds/Sign-up.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & .link-btn {
    display: flex;
    justify-content: center;
  }
  }

  ${mediaQueries.nav}{
    justify-content: start;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 600;
  color: #000030;
`;

export const AuthForm = styled.form`
  padding: 32px 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 1700px;
  margin: 0 auto;

  ${mediaQueries.nav} {
    width: 100%;
    border-radius: 0px;
  }
  @media (max-width: 576px) {
    padding : 20px 10px;
  }
`;

export const Input = styled.input`
  padding: 12px 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  font-size: 16px;
  border: 1px solid #dbdde5;
`;

export const Error = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: start;
`;

export const SubmitButton = styled.button`
  //   padding: 12px 20px;
  border-radius: 5px;
  border: none;
  background-color: #22b484;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  &:disabled {
    cursor: no-drop;
    opacity: 0.8;
  }
`;

export const SignInButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 20px;
  position: absolute;
  width: 439px;
  height: 48px;
  left: calc(50% - 439px / 2 - 0.5px);
  top: 394px;
  background: #51b380;
  border-radius: 5px;
`;

export const Preview = styled.div`
  padding: 20px;
  background-color: #ccc;
`;

export const Navigation = styled.p`
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  fontweight: normal;
  fontsize: 16px;
  lineheight: 16px;
  letterspacing: 0.15px;
  fontfamily: Open Sans;
  fontstyle: normal;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 18px;
`;

export const CaptchaStyled = styled.div`
  & .captcha-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;

  }
  & .reload-icon {
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0;
  }
  & .captcha-desc {
    color: #3b414b;
    font-size: 18px;
  }
  & .captcha-input-wrap {
  }
  & .captcha-input {
    padding: 12px 10px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    border: 1px solid #dbdde5;
    text-transform: uppercase;
    &::placeholder {
      text-transform: capitalize;
    }
  }
  & .error {
    color: red;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: start;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 auto 20px;
  cursor: pointer;
`;

export const HeaderTitle = styled.h1`
  color: #000;
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  max-width: 880px;
  margin: 0;
  & > span {
    color: #51b380;
  }
  @media (max-width: 1600px) {
    font-size: 40px;
  }
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const ImageWrapper = styled.div`
  margin: 0 auto 10px;
  max-width: 500px;
`;

export const Text = styled.p<{color? : string, fontSize? : string, margin? : string}>`
  color: ${({color}) => color ? color : '#000'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '18px'};
  line-height: 1.5;
  text-align: center;
  margin: ${({margin}) => margin ? margin : '0'};

  @media (max-width: 1600px) {
    font-size: 22px;
  }
  @media (max-width: 1200px) {
    font-size: 18px;
    margin : 15px 0;
  }
`;

export const PlatformItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: rgba(217, 217, 217, 0.2);
  padding : 10px 35px;
  margin-right: 20px;
`;
