import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {error} from "console";
import jwtDefaultConfig from "./jwtConfig";
import Cookies from "js-cookie";
import {handleLogout} from "../../../redux/action/auth";
import {Dispatch} from "redux";
import {ACCESS_TOKEN} from "../../../constant";

const {REACT_APP_API_URL, REACT_APP_WEB_DOMAIN} = process.env;
let subscribers: Array<any> = [];
let isAlreadyFetchingAccessToken = false;

declare module "axios" {
    export interface AxiosRequestConfig {
        withToken?: boolean;
    }
}

const axiosClient = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        "content-type": "application/json",
    },
});

const instanceSetup = (store: any) => {
    axiosClient.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            if (config?.withToken) {
                const accessToken = getToken();
                if (accessToken) {
                    config.headers![
                        "Authorization"
                        ] = `${jwtDefaultConfig.tokenType} ${accessToken}`;
                }
            }
            return config;
        },
        (error: any) => Promise.reject(error)
    );

    axiosClient.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: any) => {
            const {config, response} = error;
            const request = config;
            if (response && response.status === 403) {
                if (!isAlreadyFetchingAccessToken) {
                    isAlreadyFetchingAccessToken = true;
                    refreshToken().then((r) => {
                        isAlreadyFetchingAccessToken = false;
                        // ** Update accessToken in localStorage
                        setToken(r.data.access);
                        // setRefreshToken(r.data.refresh);
                        onAccessTokenFetched(r.data.access);
                    }).catch((r) => {
                        isAlreadyFetchingAccessToken = false;
                        store.dispatch(handleLogout());
                    });
                }
                const retryOriginalRequest = new Promise((resolve) => {
                    addSubscriber(() => {
                        request.headers.Authorization = `${
                            jwtDefaultConfig.tokenType
                        } ${getToken()}`;
                        resolve(axios(request));
                    });
                });
                return retryOriginalRequest;
            }
            return Promise.reject(response.data);
        }
    );
}

const onAccessTokenFetched = (accessToken: any) => {
    subscribers = subscribers.filter((callback: Function) =>
        callback(accessToken)
    );
};

const addSubscriber = (callback: Function) => {
    subscribers.push(callback);
};

const getToken = () => {
    return Cookies.get(jwtDefaultConfig.storageTokenKeyName);
};

const getRefreshToken = () => {
    return Cookies.get(jwtDefaultConfig.storageRefreshTokenKeyName);
};

const setToken = (value: any) => {
    const now = new Date();
    // Cookies.set(jwtDefaultConfig.storageTokenKeyName, value,{domain: REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN, });
    document.cookie = `${ACCESS_TOKEN}=${value}; domain=${
        REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
    }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
};

const setRefreshToken = (value: any) => {
    Cookies.set(jwtDefaultConfig.storageRefreshTokenKeyName, value);
};

//   const login = (...args) => {
//     return axios.post(jwtDefaultConfig.loginEndpoint, ...args)
//   }

//   register(...args) {
//     return axios.post(jwtDefaultConfig.registerEndpoint, ...args)
//   }

const refreshToken = () => {
    return axiosClient.post(jwtDefaultConfig.refreshEndpoint, {
        refresh: getRefreshToken(),
    });
};

export {axiosClient, instanceSetup};
