import {PayloadAction} from "@reduxjs/toolkit";
import {CLOSE_ADD_OR_EDIT_USER_MODAL, DELETE_USER, GET_USER_DETAIL, GET_USER_LIST} from "../../actionType";

interface userReducerType {
    userList: Array<any>;
    userListTotal: number;
    updateUserList: boolean;
    userDetail: any;
}

const initialState: userReducerType = {
    userList: [],
    userListTotal: 0,
    updateUserList: false,
    userDetail: {},
}

const userReducer = (state = initialState, action: PayloadAction<any>): userReducerType => {
    switch (action.type) {
        case GET_USER_LIST:
            return {
                ...state,
                userList: action.payload.results,
                userListTotal: action.payload.count,
            }
        case DELETE_USER:
            return {
                ...state,
                updateUserList: !state.updateUserList,
            }
        case GET_USER_DETAIL:
            return {
                ...state,
                userDetail: action.payload
            }
        case CLOSE_ADD_OR_EDIT_USER_MODAL:
            return {
                ...state,
                userDetail: {},
                updateUserList: !state.updateUserList,
            }
        default:
            return state;
    }
}

export default userReducer;