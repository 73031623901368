import {axiosClient} from "../../../@core/auth/jwt/jwtService";
import queryString from "query-string";

export const getProfileList = (data: any) => {
    // return axiosClient.get("/api/admin/companies/?" + queryString.stringify(data, {
    //     skipNull: true,
    //     skipEmptyString: true
    // }), {withToken: true});
    return axiosClient.get("/api/companies/?" + queryString.stringify(data, {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: "comma",
    }), {withToken: true});
}

export const deleteProfile = (id: number) => {
    return axiosClient.delete(`/api/admin/companies/${id}/`, {withToken: true});
}

export const createProfile = (data: any) => {
    return axiosClient.post("/api/admin/companies/", data, {withToken: true});
}

export const updateProfile = (data: any, profileId: number) => {
    return axiosClient.patch(`/api/admin/companies/${profileId}/`, data, {withToken: true});
}

export const getProfile = (id: number) => {
    return axiosClient.get(`/api/admin/companies/${id}/`, {withToken: true});
}

export const getUserCompany = () => {
    return axiosClient.get("/api/me/companies/", {withToken: true})
}

export const removeCompanyUser = (data: any, profileId: number) => {
    return axiosClient.patch(`/api/admin/companies/${profileId}/remove_user/`, data, {withToken: true});
}

export const createProfileUser = (data: any) => {
    return axiosClient.post("/api/companies/", data, {withToken: true});
}

export const updateProfileUser = (data: any, profileId: number) => {
    return axiosClient.patch(`/api/companies/${profileId}/`, data, {withToken: true});
}

export const getProfileReviewText = (data: any, profileId: number) => {
    return axiosClient.get(`/api/companies/${profileId}/reviews/?` + queryString.stringify(data, {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: "comma",
    }), {withToken: true})
}

export const getProfileReviewSource = (profileId: number) => {
    return axiosClient.get(`/api/companies/${profileId}/review-sources/`, {withToken: true});
}

// export const getExportExcel = (data?: any) => {
//     return axiosClient.get("/api/admin/companies/export-excel/?" + queryString.stringify(data, {
//         skipNull: true,
//         skipEmptyString: true,
//         arrayFormat: "comma",
//     }), { withToken: true, responseType: "blob" }
//     ).then(res => new Blob([res.data]));
// }

export const getExportExcel = (data?: any) => {
    return axiosClient.get("/api/admin/companies/export-excel/?" + queryString.stringify(data, {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: "comma",
    }), { withToken: true });
}

export const deleteCompanyById = (id: any) => {
	return axiosClient.delete(`/api/admin/companies/${id}`)
}


export const getIpLocalMachine = () => {
    return axiosClient.get(`https://api.ipify.org/?format=json`);
}