import { PayloadAction } from "@reduxjs/toolkit"
import { GET_DASHBOARD_DATA } from "../../actionType";

interface DashboardDataType {
    count_user_signed_up_set: number;
    count_user_non_paying: number;
    count_user_trial: number;
    count_user_paid: number;
    count_total_user: number;
}

const initialState: DashboardDataType = {
    count_user_signed_up_set: 0,
    count_user_non_paying: 0,
    count_user_trial: 0,
    count_user_paid: 0,
    count_total_user: 0
}

const dashboardReducer = (
    state = initialState, 
    action: PayloadAction<any>): DashboardDataType => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default dashboardReducer;