import {Dialog, IconButton, styled} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import VerifyTrustStarIcon from "../../Icon/VerifyTrustStarIcon";

const StyledDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-paper": {
        padding: 32,
        backgroundColor: theme.custom.color.white,
        borderRadius: 10,
        boxShadow: '0px 20px 55px rgba(0, 0, 0, 0.1)',
        position: "relative",
        minHeight: 790,
        overflow: "hidden",
    },
    '& .MuiBackdrop-root': {
        backgroundColor: '#000030'
    },
}))

const StarIcon1 = styled("div")({
    position: "absolute",
    bottom: '25.41px',
    left: '33.34px',
})

const StarIcon2 = styled("div")({
    position: "absolute",
    top: '94.46px',
    right: '-110.28px',
})

type PropsType = {
    children: React.ReactNode,
    onClose?: () => void,
    open: boolean,
}

const SignUpModalStyle = (props: PropsType) => {
    return (
        <StyledDialog open={props.open} fullWidth maxWidth={"lg"} onClose={props.onClose}>
            {props.onClose && (
                <IconButton
                    onClick={props.onClose}
                    sx={{
                        position: "absolute",
                        right: 20,
                        top: 20,
                        color: (theme) => theme.custom.color.secondary,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            )}
            {props.children}
            <StarIcon2>
                <VerifyTrustStarIcon/>
            </StarIcon2>
            <StarIcon1>
                <VerifyTrustStarIcon/>
            </StarIcon1>
        </StyledDialog>
    )
}

export default SignUpModalStyle;