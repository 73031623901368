import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../utility/hooks/useSelector";
import { LogoTextHorizontal } from "../Icon";
import MenuIcon from "@mui/icons-material/Menu";

const AvatarStyled = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
`;

interface HeaderMobileProps {
  onToggleMenuBar: () => void;
}

const HeaderMobile: FC<HeaderMobileProps> = ({ onToggleMenuBar }) => {
  const userData = useAppSelector((state) => state.auth.userData);
  const url = userData.profile?.photo;

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <Stack direction={"row"} spacing={2}>
          <MenuIcon onClick={onToggleMenuBar} />
          <LogoTextHorizontal />
        </Stack>
      </Grid>
      <Grid item>
        <AvatarStyled
          src={
            url ||
            "https://cdn.trustindex.io/assets/default-avatar/noprofile-06.svg"
          }
          alt="avatar"
        />
      </Grid>
    </Grid>
  );
};

export default HeaderMobile;
