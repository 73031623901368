import {createTheme} from "@mui/material";

//Add custom variables
declare module "@mui/material" {
    interface Theme {
        custom: {
            color: {
                primary: string;
                secondary: string;
                green: string;
                white: string;
                gray: string;
                gray2: string;
                border: string;
                red: string;
                red2: string;
                blue: string;
                dark4: string;
                gray3: string;
            };
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        custom?: {
            color?: {
                primary?: string;
                secondary?: string;
                green?: string;
                white?: string;
                gray: string;
                gray2?: string;
                border?: string;
                red?: string;
                red2?: string;
                blue?: string;
                dark4?: string;
                gray3?: string;
            };
        };
    }
}

//Main theme options
const themes = createTheme({
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        h5: {
            fontWeight: 600,
            fontSize: 24,
            fontStyle: "normal",
        },
        body1: {
            fontSize: "16px",
            fontWeight: 400,
            fontStyle: "normal",
            lineHeight: "24px",
        },
        body2: {
          fontSize: 18,
          fontWeight: 600,
          fontStyle: 'normal',
        },
        h4: {
            fontWeight: 600,
            fontSize: 30,
            fontStyle: "normal",
        },
        caption: {
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "14px",
            color: "#979DA0",
        },
        h3: {
            fontSize: 20,
            fontWeight: 400,
            fontStyle: "normal",
        },
        h1: {
            fontSize: 64,
            fontWeight: 600,
        },
        h2: {
            fontSize: 26,
            fontWeight: "bold",
        }
    },
    custom: {
        color: {
            primary: "#000030",
            secondary: "#536283",
            green: "#51B380",
            white: "#FFFFFF",
            gray: "#FFFAF9",
            gray2: "#979DA0",
            border: "#DBDFEA",
            red: "#FF6663",
            blue: "#00A79D",
            red2: "#F16037",
            dark4: "#8F90A6",
            gray3: "#F5F6FA",
        },
    },
});

//Override components style
const theme = createTheme(themes, {
    palette: {
        primary: {
            main: themes.custom.color.green,
            light: themes.custom.color.green,
            dark: themes.custom.color.green,
            contrastText: themes.custom.color.white,
        },
        error: {
            main: themes.custom.color.red2,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 16,
                    fontSize: 16,
                    fontWeight: "bold",
                    letterSpacing: 0.15,
                    textTransform: "none",
                    boxShadow: "none",
                    height: 48,
                },
                containedPrimary: {
                    color: themes.custom.color.white,
                },
                containedInherit: {
                    color: themes.custom.color.white,
                    backgroundColor: themes.custom.color.gray2,
                    "&:hover": {
                        backgroundColor: themes.custom.color.gray2,
                    }
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        background: "rgba(81, 179, 128, 0.1)",
                        "& .MuiListItemText-root": {
                            color: themes.custom.color.green,
                        },
                        "& path": {
                            fill: themes.custom.color.green,
                        },
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: themes.custom.color.secondary,
                    "& .MuiTypography-root": {
                        fontSize: 14,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    "& svg": {
                        width: 16,
                        height: 16,
                    },
                    minWidth: 28,
                },
            },
        },
        // MuiPaper: {
        //     styleOverrides: {
        //         root: {
        //             boxShadow: "none",
        //         },
        //     },
        // },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: themes.custom.color.red2,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontSize: 14,
                },
                body: {
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: 14,
                },
                root: {
                    padding: "20px 12px",
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: `1px solid ${themes.custom.color.border}`
                }
            }
        },
        MuiTableRow: {
          styleOverrides: {
              root: {
                  borderStyle: "hidden",
              }
          }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline ': {
                        borderColor: `${themes.custom.color.border} !important` ,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${themes.custom.color.border}` ,
                    },
                }
            }
        }
    },
});

export default theme;
