import React from 'react'

const Country = () => {
  return (
<img src="/assets/images/sign-up/country.png" alt="website" />

  )
}

export default Country
