import React from "react";

const User = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6458 14.7134C15.5789 14.6732 15.5005 14.4256 15.6419 13.9116C17.1514 12.2701 18.0832 9.91918 18.0832 7.72193C18.0832 4.22765 15.6633 1.76742 12.3392 1.76742C9.02246 1.76742 6.70569 4.20774 6.70569 7.70211C6.70569 9.93007 7.64698 12.2985 9.17129 13.9309C9.32426 14.444 9.12483 14.6281 8.99866 14.6857C7.48679 15.2325 1.8125 17.2317 1.8125 19.997V23.6164H23.0512V22.7991V21.9813V19.5292C22.3163 16.9941 16.9479 15.1417 15.6458 14.7134ZM3.33683 22.0921V20.1077C3.33683 19.0626 6.49581 17.3299 9.55751 16.2229C9.68349 16.1762 10.331 15.9073 10.671 15.1624C10.8515 14.767 10.9939 14.1071 10.6501 13.204L10.5895 13.0452L10.47 12.9241C9.17638 11.6022 8.34079 9.55295 8.34079 7.70202C8.34079 4.52887 10.4953 3.40242 12.3392 3.40242C14.189 3.40242 16.4636 4.55408 16.4636 7.72735C16.4636 9.55991 15.6275 11.6053 14.3339 12.9376C14.3339 12.9376 13.7856 13.7107 13.7931 14.2669C13.798 14.633 14.0034 15.0936 14.1647 15.4107C14.4684 16.0095 14.9671 16.2095 15.1084 16.2561C17.9453 17.1949 21.2063 18.4474 21.5268 19.5515V22.092H3.33683V22.0921Z"
        fill="white"
      />
    </svg>
  );
};

export default User;
