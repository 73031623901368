import {SvgProps} from "./types";

const AddUserIcon: React.FC<SvgProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
            <path
                d="M13 9.99992C14.8416 9.99992 16.3333 8.50825 16.3333 6.66658C16.3333 4.82492 14.8416 3.33325 13 3.33325C11.1583 3.33325 9.66665 4.82492 9.66665 6.66658C9.66665 8.50825 11.1583 9.99992 13 9.99992ZM5.49998 8.33325V6.66658C5.49998 6.20825 5.12498 5.83325 4.66665 5.83325C4.20831 5.83325 3.83331 6.20825 3.83331 6.66658V8.33325H2.16665C1.70831 8.33325 1.33331 8.70825 1.33331 9.16658C1.33331 9.62492 1.70831 9.99992 2.16665 9.99992H3.83331V11.6666C3.83331 12.1249 4.20831 12.4999 4.66665 12.4999C5.12498 12.4999 5.49998 12.1249 5.49998 11.6666V9.99992H7.16665C7.62498 9.99992 7.99998 9.62492 7.99998 9.16658C7.99998 8.70825 7.62498 8.33325 7.16665 8.33325H5.49998ZM13 11.6666C10.775 11.6666 6.33331 12.7832 6.33331 14.9999V15.8333C6.33331 16.2916 6.70831 16.6666 7.16665 16.6666H18.8333C19.2916 16.6666 19.6666 16.2916 19.6666 15.8333V14.9999C19.6666 12.7832 15.225 11.6666 13 11.6666Z"
                fill={props.fill || "#536283"}/>
        </svg>
    )
}

export default AddUserIcon;