import _ from "lodash";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Navigate } from "react-router";
import { handleGetUserData } from "../../redux/action/auth";
import { useAppSelector } from "../../utility/hooks/useSelector";
import PaymentRedirectGlobal from "../../constant/PaymentRedirectGlobal";

const PrivateRoute = ({
  children,
  adminOnly = false,
  userOnly = false,
  loginOnly = false,
}: {
  children?: React.ReactNode;
  adminOnly?: boolean;
  userOnly?: boolean;
  loginOnly?: boolean;
}) => {
  const accessToken = useAppSelector((state) => state.auth.access);
  const { userData } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(handleGetUserData());
    })();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      
      const isNotAdminOnly = !adminOnly === false;
      const isNotSuperUser = userData.is_superuser === false;
      const isNotOwner = !userData.is_owner === false;
      const isTrialCompany = userData.companies[0] && userData.companies[0].is_trial === true;
      const isInitTrialCompany = userData.companies[0] && userData.companies[0].is_init_trial === true;
      if (isNotAdminOnly && isNotSuperUser && isNotOwner  && isTrialCompany && isInitTrialCompany) {
        setTimeout(() => {
          return <PaymentRedirectGlobal userData={userData} />;
        }, 500);
      }
    }
  }, [userData]);

  if (_.isEmpty(accessToken) && !loginOnly) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (
    (!userData?.is_superuser && adminOnly) ||
    (userData.is_superuser && userOnly) ||
    (loginOnly && !_.isEmpty(accessToken))
  ) {
    if (userData?.is_superuser) {
      return (
        <Navigate to={`/accountsettings`} state={{ from: location }} />
      )
    } else if (userData?.is_superuser === false && !userData?.is_owner && userData?.companies[0].is_trial && userData?.companies[0].is_init_trial) {
      return (
        <PaymentRedirectGlobal userData={userData} />
      )
    } else {
      setTimeout(() => {
        return (
          <Navigate
            to={`/profiles/${userData ? userData.company : 0}`}
            state={{ from: location }}
          />
        );
      }, 1000);
    }
  };

  return <>{children}</>;
};

export default PrivateRoute;
