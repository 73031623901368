import { FC } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
import { useAppSelector } from "../../utility/hooks/useSelector";
import { LogoTextHorizontal } from "../Icon";
import VerticalLine from "../Line/VerticalLine";
import { useRouter } from "../../utility/hooks/useRouter";
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import ShortcutIcon from '@mui/icons-material/Shortcut';
interface HeaderDesktopProps {}

const AvatarStyled = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
`;

const HeaderDesktop: FC<HeaderDesktopProps> = () => {
  const theme = useTheme();
  const userData = useAppSelector((state) => state.auth.userData);
  const url = userData.profile?.photo;
  const router = useRouter();

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <Grid container>
          <Grid item>
            {/* <LogoTextHorizontal /> */}
			      <img src="/assets/default-logo/logo-light.png" width="68"/>

          </Grid>
          <Grid item style={{ margin: "5px 24px" }}>
            <VerticalLine />
          </Grid>
          <Grid item style={{ padding: "7px 0" }}>
            {userData.is_superuser === false ? (
              <a href={`${process.env.REACT_APP_WEB_CLIENT_URL}/reviews/${userData.companies[0].url}`} style={{color: '#ffffff', fontWeight: 600}} target="_blank"><UTurnLeftIcon style={{width: 15, height: 15,transform: 'rotate(-93deg)'}}/> Visit your profile</a>
            ) : (
              <>
                <Typography
                  variant={"h3"}
                  style={{
                    color: theme.custom.color.gray2,
                    lineHeight: "18px",
                  }}
                >
                  Welcome to Verify Trusted
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item style={{ marginRight: 8 }}>
            <Typography variant={"caption"}>
              Hello&nbsp;
              <span style={{ color: "white" }}>{userData?.name}</span>
            </Typography>
          </Grid>
          <Grid item>
            <AvatarStyled
              src={
                url ||
                "https://cdn.trustindex.io/assets/default-avatar/noprofile-06.svg"
              }
              alt="avatar"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderDesktop;
