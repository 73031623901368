import { Cookie } from "../../interface/Cookie";

export const getCookies = () => {
    const cookieObj: Cookie = document.cookie.split(';').reduce((res, c) => {
        const [key, val] = c.trim().split('=').map(decodeURIComponent);
        try {
            return Object.assign(res, { [key]: JSON.parse(val) });
        } catch (e) {
            return Object.assign(res, { [key]: val });
        }
    }, {});
    return cookieObj;
}