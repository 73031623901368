export const LOGIN = "LOGIN";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_ERROR = "LOGGIN_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const UPDATE_ACCOUNT_SETTING = "UPDATE_ACCOUNT_SETTING";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND";
export const LOGIN_WRONG_INFO = "LOGIN_WRONG_INFO";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_INVALID = "VALIDATE_TOKEN_INVALID";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const GET_PROFILE_LIST = "GET_PROFILE_LIST";
export const GET_PROFILE_LIST_FAIL = "GET_PROFILE_LIST_FAIL";
export const CLOSE_ASSGIN_USER_MODAL = "CLOSE_ASSGIN_USER_MODAL";
export const OPEN_ASSGIN_USER_MODAL = "OPEN_ASSGIN_USER_MODAL";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL";
export const CLOSE_DELETE_MODAL = "CLOSE_DELETE_MODAL";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const OPEN_ADD_OR_EDIT_USER_MODAL = "OPEN_ADD_OR_EDIT_USER_MODAL";
export const CLOSE_ADD_OR_EDIT_USER_MODAL = "CLOSE_ADD_OR_EDIT_USER_MODAL";
export const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL";
export const GET_RESOURCE_LIST = "GET_RESOURCE_LIST";
export const EDIT_RESOURCE = "EDIT_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const LOADING_RESOURCE = "LOADING_RESOURCE";
export const MODAL_RESOURCE = "MODAL_RESOURCE";
export const GET_SOURCE_REVIEW = "GET_SOURCE_REVIEW";
export const CREATE_WIDGETS = "CREATE_WIDGETS";
export const CREATE_WIDGETS_FAIL = "CREATE_WIDGETS_FAIL";
export const GET_ALL_WIDGETS = "GET_ALL_WIDGETS";
export const GET_ALL_WIDGETS_FAIL = "GET_ALL_WIDGETS_FAIL";
export const GET_WIDGET = "GET_WIDGET";
export const GET_WIDGET_FAIL = "GET_WIDGET_FAIL";
export const GET_SOURCE_SOCIAL = "GET_SOURCE_SOCIAL";
export const EDIT_REVIEW_SOURCE = "EDIT_REVIEW_SOURCE";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const GET_PLATFORM_LIST = "GET_PLATFORM_LIST";
export const RESET_PROFILE_DETAIL = "RESET_PROFILE_DETAIL";
export const REFRESH_PROFILE_LIST = "REFRESH_PROFILE_LIST";
export const REVIEW_SOURCE_COMPANY = "REVIEW_SOURCE_COMPANY";
export const USER_REVIEW_SOURCE_MODAL = "USER_REVIEW_SOURCE_MODAL";
export const PLATFORM_TO_HANDLE = "PLATFORM_TO_HANDLE";
export const PLATFORM_LIST = "PLATFORM_TO_ADD";
export const GET_WIDGET_COMPANY_ID = "GET_WIDGET_COMPANY_ID";
export const GET_WIDGET_COMPANY_ID_FAIL = "GET_WIDGET_COMPANY_ID_FAIL";
export const GET_PROFILE_ME = "GET_PROFILE_ME";
export const GET_PROFILE_ME_FAIL = "GET_PROFILE_ME_FAIL";
export const ASSGIN_USER_NEW_PROFILE = "ASSGIN_USER_NEW_PROFILE";
export const ASSIGN_USER_NEW_PROFILE = "ASSIGN_USER_NEW_PROFILE";
export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const EDIT_WIDGET_SUCCESS = "EDIT_WIDGET_SUCCESS";
export const EDIT_WIDGET_FAIL = "EDIT_WIDGET_FAIL";
export const UPDATE_WIDGET_SUCCESS = "UPDATE_WIDGET_SUCCESS";
export const UPDATE_WIDGET_FAIL = "UPDATE_WIDGET_FAIL";
export const GET_ALL_SEALS = "GET_ALL_SEALS";
export const GET_ALL_SEALS_FAIL = "GET_ALL_SEALS_FAIL";
export const CREATE_SEAL = "CREATE_SEAL";
export const CREATE_SEAL_FAIL = "CREATE_SEAL_FAIL";
export const GET_SEAL_WITH_COMPANYID = "GET_SEAL_WITH_COMPANYID";
export const GET_SEAL_WITH_COMPANYID_FAIL = "GET_SEAL_WITH_COMPANYID_FAIL";
export const EDIT_SEAL = "EDIT_SEAL";
export const EDIT_SEAL_FAIL ="EDIT_SEAL_FAIL";
export const GET_REVIEWSOURCE_BY_COMPANYID = "GET_REVIEWSOURCE_BY_COMPANYID";
export const GET_CHANGE_LOG_LIST = "GET_CHANGE_LOG_LIST";
export const GET_CHANGE_LOG_LIST_FAIL = "GET_CHANGE_LOG_LIST_FAIL";
export const DELETE_CHANGE_LOG = "DELETE_CHANGE_LOG";
export const UPDATE_CHANGE_LOG = "UPDATE_CHANGE_LOG";
export const CREATE_CHANGE_LOG = "CREATE_CHANGE_LOG";
export const GET_CHANGE_LOG_DETAIL = "GET_CHANGE_LOG_DETAIL";
export const CREATE_PROFILE_REVIEW = "CREATE_PROFILE_REVIEW";
export const EDIT_PROFILE_REVIEW = "EDIT_PROFILE_REVIEW";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_PAYMENT_LIST_FAIL = "GET_PAYMENT_LIST_FAIL";
export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const SET_USER_PROGRESS = "SET_USER_PROGRESS";
export const SET_PROFILE_PROGRESS = "SET_PROFILE_PROGRESS";
export const SET_WEBSITE_PROGRESS = "SET_WEBSITE_PROGRESS";
export const OPEN_PAYMENT_DETAIL_MODAL = "OPEN_PAYMENT_DETAIL_MODAL";
export const CLOSE_PAYMENT_DETAIL_MODAL = "CLOSE_PAYMENT_DETAIL_MODAL";
export const CHECK_REGISTER_SUCCESS = "CHECK_REGISTER_SUCCESS";
export const DEACTIVE_ACCOUNT = "DEACTIVE_ACCOUNT";
export const OPEN_REVIEW_SOURCE_DETAIL_MODAL = "OPEN_REVIEW_SOURCE_DETAIL_MODAL";
export const CLOSE_REVIEW_SOURCE_DETAIL_MODAL = "CLOSE_REVIEW_SOURCE_DETAIL_MODAL";
export const OPEN_REVIEW_TEXT_DETAIL_MODAL = "OPEN_REVIEW_TEXT_DETAIL_MODAL";
export const CLOSE_REVIEW_TEXT_DETAIL_MODAL = "CLOSE_REVIEW_TEXT_DETAIL_MODAL";
export const GET_REVIEW_SOURCE_CHANGE_LOG = "GET_REVIEW_SOURCE_CHANGE_LOG";
export const GET_REVIEW_SOURCE_CHANGE_FAIL = "GET_REVIEW_SOURCE_CHANGE_FAIL";
export const GET_REVIEW_CHANGE_LOG = "GET_REVIEW_CHANGE_LOG";
export const GET_REVIEW_CHANGE_FAIL = "GET_REVIEW_CHANGE_FAIL";
export const UPDATE_USERDATA = "UPDATE_USERDATA";
export const UPDATE_REVIEWSORT = "UPDATE_REVIEWSORT"
export const REGISTER_ERROR_IP = "REGISTER_ERROR_IP"
export const REVIEW_COUNT = "REVIEW_COUNT"