import * as yup from "yup";
import { errorMessages } from "./errorMessages";
import { EMAIL_REG, FULL_NAME_REG, ONLY_SPACE_REG } from "./regexConstans";

export const userNameYupValidate = {
    username: yup
        .string()
        .required(errorMessages.MSG001)
        .matches(ONLY_SPACE_REG, errorMessages.MSG002)
        .matches(
            FULL_NAME_REG,
            errorMessages.MSG002
        )
        .min(6, errorMessages.MSG003)
        .max(32, errorMessages.MSG003)
};

export const urlYupValidate = {
    url: yup
        .string()
        .required(errorMessages.MSG013)
        .matches(
            /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
            errorMessages.MSG014
        )
        .strict(true)
};

export const passwordYupValidate = {
    password: yup
        .string()
        .required(errorMessages.MSG007)
        .min(6, errorMessages.MSG008)
        .max(255, "Password requires up to 255 characters.")
        .matches(/^(?=.*\d).*$/gm, errorMessages.MSG010)
        .matches(
            /^(?=.*[a-z]).*$/gm,
            errorMessages.MSG011
        )
        .matches(
            /^(?=.*[A-Z]).*$/gm,
            errorMessages.MSG012
        )
        .matches(/^(?=.*[*.!@$%^&(){}:;<>,.?/~_+-=|]).*$/gm, errorMessages.MSG009)
};

export const emailYupValidate = {
    email: yup
        .string()
        .max(255)
        .required(errorMessages.MSG005)
        .matches(
            EMAIL_REG,
            errorMessages.MSG006
        )
};

export const companyNameYupValidate = {
    companyName: yup
        .string()
        .required(errorMessages.MSG004)
        .matches(ONLY_SPACE_REG, errorMessages.MSG004)
        .strict(true)
        .max(255, "Company name requires up to 255 characters."),
};

export const countryCodeYupValidate = {
    country_code: yup
        .string()
        .required('Country is required')
        .matches(ONLY_SPACE_REG, errorMessages.MSG004)
        .strict(true)
};