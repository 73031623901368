import { PayloadAction } from "@reduxjs/toolkit";
import {
    CLOSE_ADD_OR_EDIT_USER_MODAL,
    CLOSE_ASSGIN_USER_MODAL,
    CLOSE_DELETE_MODAL,
    OPEN_ADD_OR_EDIT_USER_MODAL,
    OPEN_ASSGIN_USER_MODAL,
    OPEN_DELETE_MODAL, SET_PROFILE_PROGRESS, SET_USER_PROGRESS, SET_WEBSITE_PROGRESS,
    OPEN_PAYMENT_DETAIL_MODAL,
    CLOSE_PAYMENT_DETAIL_MODAL,
    OPEN_REVIEW_SOURCE_DETAIL_MODAL,
    CLOSE_REVIEW_SOURCE_DETAIL_MODAL,
    OPEN_REVIEW_TEXT_DETAIL_MODAL,
    CLOSE_REVIEW_TEXT_DETAIL_MODAL
} from "../../actionType";
import { speedDialActionClasses } from "@mui/material";
import { CLEAR_REDUX } from "../../../constant";

interface modalReducerType {
    openAssignUser: boolean,
    assignUserTarget: any,
    assignUserType: number,
    openDelete: boolean,
    deleteTarget: any,
    deleteType: number,
    openAddOrEditUser: boolean,
    AddOrEditUserTarget: any,
    addOrEditUserType: number,
    addOrEditUserReset: boolean,
    userProgress: number,
    profileProgress: number,
    websiteProgress: number,
    openPaymentDetail: boolean,
    paymentDetailTarget: any,
    paymentDetailType: number,
    paymentId: any,
    openReviewSourceDetail: boolean,
    titleReviewSourceDetail: string,
    openReviewTextDetail: boolean,
    titleReviewTextDetail: string
}

const initialState: modalReducerType = {
    openAssignUser: false,
    assignUserTarget: {},
    assignUserType: 0,
    openDelete: false,
    deleteTarget: {},
    deleteType: 0,
    openAddOrEditUser: false,
    AddOrEditUserTarget: {},
    addOrEditUserType: 0,
    addOrEditUserReset: false,
    userProgress: 0,
    profileProgress: 0,
    websiteProgress: 0,
    openPaymentDetail: false,
    paymentDetailTarget: {},
    paymentDetailType: 0,
    paymentId: null,
    openReviewSourceDetail: false,
    titleReviewSourceDetail: '',
    openReviewTextDetail: false,
    titleReviewTextDetail: '',
}

const profileReducer = (state = initialState, action: PayloadAction<any>): modalReducerType => {
    switch (action.type) {
        case OPEN_ASSGIN_USER_MODAL:
            return {
                ...state,
                openAssignUser: true,
                assignUserTarget: action.payload.target,
                assignUserType: action.payload.type,
            }
        case CLOSE_ASSGIN_USER_MODAL:
            return {
                ...state,
                openAssignUser: false,
                assignUserTarget: {},
            }
        case OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: true,
                deleteTarget: action.payload.data,
                deleteType: action.payload.type,
            }
        case CLOSE_DELETE_MODAL:
            return {
                ...state,
                openDelete: false,
                deleteTarget: {},
                deleteType: 0,
            }
        case OPEN_ADD_OR_EDIT_USER_MODAL:
            return {
                ...state,
                openAddOrEditUser: true,
                AddOrEditUserTarget: action.payload.data,
                addOrEditUserType: action.payload.type,
            }
        case CLOSE_ADD_OR_EDIT_USER_MODAL:
            return {
                ...state,
                openAddOrEditUser: false,
                AddOrEditUserTarget: {},
                addOrEditUserType: 0,
                addOrEditUserReset: !state.addOrEditUserReset,
            }
        case OPEN_PAYMENT_DETAIL_MODAL:
            return {
                ...state,
                openPaymentDetail: true,
                paymentId: action.payload,
                // paymentDetailTarget: action.payload.results,
                // paymentDetailType: action.payload.type,
            }
        case CLOSE_PAYMENT_DETAIL_MODAL:
            return {
                ...state,
                openPaymentDetail: false,
                // paymentDetailTarget: {},
                // paymentDetailType: 0,
                // addOrEditUserReset: !state.addOrEditUserReset,
            }
        case SET_USER_PROGRESS:
            return {
                ...state,
                userProgress: action.payload,
            }
        case SET_PROFILE_PROGRESS:
            return {
                ...state,
                profileProgress: action.payload,
            }
        case SET_WEBSITE_PROGRESS:
            return {
                ...state,
                websiteProgress: action.payload,
            }
        case CLEAR_REDUX:
            return {
                ...initialState,
            }
        case OPEN_REVIEW_SOURCE_DETAIL_MODAL:
            return {
                ...state,
                openReviewSourceDetail: true,
                titleReviewSourceDetail: action.payload.title
            }
        case CLOSE_REVIEW_SOURCE_DETAIL_MODAL:
            return {
                ...state,
                openReviewSourceDetail: false,
            }
        case OPEN_REVIEW_TEXT_DETAIL_MODAL:
            return {
                ...state,
                openReviewTextDetail: true,
                titleReviewTextDetail: action.payload.title
            }
        case CLOSE_REVIEW_TEXT_DETAIL_MODAL:
            return {
                ...state,
                openReviewTextDetail: false,
            }
        default:
            return state;
    }
}

export default profileReducer;