import {
    Button,
    Grid,
    styled,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { closeReviewTextDetailModal } from "../../../redux/action/modal";
import { useAppSelector } from "../../../utility/hooks/useSelector";
import PaymentHistoryModal from "../component/PaymentHistoryModal";
import { Divider } from "antd";

const StyleHeadTypo = styled(Typography)`
    position: static;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000030;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
  `;

const StyleTypo = styled(Typography)`
    position: static;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: flex-start;
    text-align: left;
    letter-spacing: 0.15px;
    color: #536283;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0; 
    margin: 0px 0px;
  `;

const StyleCloseBtn = styled(Button)`
    /* [Button] Stroke / Active */
    margin: auto;
  
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    position: absolute;
    /* White */
    background: #ffffff;
    /* Gray 2 */
    border: 1px solid #979da0;
    box-sizing: border-box;
    border-radius: 4px;
    /* Icons */
  
    position: static;
    display: none;
  
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
  
    /* Text */
  
    position: static;
  
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
  
    /* Primary */
    color: #000030;
  
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
  `;

const ReviewTextDetailModal = () => {
    const dispatch = useDispatch();
    const {
        openReviewTextDetail,
        titleReviewTextDetail
    } = useAppSelector((state) => state.modal);
    // const { paymentDetail } = useAppSelector((state) => state.payment);

    // useEffect(() => {
    //     console.log(paymentDetail);
    // });

    // useEffect(() => {
    //     if (paymentId) {
    //         (async () => {
    //             dispatch(handleGetPaymentDetail(paymentId));
    //             // console.log(+paymentDetail.id)
    //         })();
    //     }
    // }, [paymentId]);

    const handleClose = () => {
        dispatch(closeReviewTextDetailModal());
    };

    return (
        <PaymentHistoryModal
            open={openReviewTextDetail}
            onClose={handleClose}
            title={titleReviewTextDetail}
            maxWidth={"md"}
            style={{
                fontSize: "23px",
                fontWeight: "40px",
                color: "#000030",
            }}
        >
            <Divider style={{ backgroundColor: "#DBDFEA" }} />
            <Grid container spacing={2}>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Review source</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>Facebook</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Reviewer name</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>David John</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Review text URL</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>https://www.facebook.com/12321</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Review date</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>12/12/2022</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Review score</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>5*</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Review text</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad asperiores et error qui nesciunt accusamus omnis, at quo eaque, rerum quae natus deleniti incidunt, perferendis deserunt soluta optio dolorem facilis.</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Display</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>true</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Display order</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>1</StyleTypo>
                </Grid>
                <Divider style={{ backgroundColor: "#DBDFEA" }} />
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Action</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>Add</StyleTypo>
                </Grid>
                <Grid item xs={4} style={{ lineHeight: "16px" }}>
                    <StyleHeadTypo>Log date</StyleHeadTypo>
                </Grid>
                <Grid item xs={8} style={{ lineHeight: "16px" }}>
                    <StyleTypo>12/12/2022</StyleTypo>
                </Grid>
                <Divider style={{ backgroundColor: "#DBDFEA" }} />
            </Grid>
            <Grid style={{ textAlign: "center" }}>
                <StyleCloseBtn onClick={handleClose} style={{ margin: "auto" }}>
                    Close
                </StyleCloseBtn>
            </Grid>
        </PaymentHistoryModal>
    );
};

export default ReviewTextDetailModal;
