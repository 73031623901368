import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthFormLayout } from "../../../components/Layout";
import { Button, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleRequestResetPassword } from "../../../redux/action/auth";
import { useAppSelector } from "../../../utility/hooks/useSelector";
import { LockIcon } from "../../../components/Icon";
import { styled } from "@mui/system";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email address is required"),
});

const BackButton = styled("a")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  "& svg": {
    width: 16,
    height: 16,
  },
  fontWeight: "normal",
  fontSize: 16,
  color: "#536283",
  "&:hover": {
    color: "#536283",
  },
});

const ForgotPassword = ({ handleChangeTab, changeForm }: { handleChangeTab: Function , changeForm: Number}) => {
  const dispatch = useDispatch();
  const lockButton = useAppSelector((state) => state.auth.lockForgotButton);
  const {
    register,
    formState: { errors, isValid, isSubmitted },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  useEffect(() => {
	localStorage.setItem('fogotPass', '0')
  },[])

  const handleResetPassword = (value: any) => {
    dispatch(handleRequestResetPassword(value.email));
  };

  const onChangeTab = () => {
    handleChangeTab(0);
    reset({ email: "" });
  };

  const submit = (e: any) => {
    handleSubmit(handleResetPassword)();
    e.preventDefault();
  };

  return (
    <AuthFormLayout
      title={"Forgot password"}
      subTitle={
        "Enter the email address you used when you joined and we’ll send you instructions to reset your password. For security reasons, we do NOT store your password. So rest assured that we will never send your password via email."
      }
      onSubmit={submit}
    >
      <TextField
        {...register("email")}
        // required
        helperText={errors.email?.message}
        error={isSubmitted && !_.isEmpty(errors.email?.message)}
        fullWidth
        placeholder={"Enter your email address"}
        label={"Email address"}
        style={{ marginBottom: 32, marginTop: 20 }}
      />
      <Button
        fullWidth
        variant={"contained"}
        color={"primary"}
        style={{ marginBottom: 20, height: 48 }}
        onClick={handleSubmit(handleResetPassword)}
      >
        Send me intructions
      </Button>
      <BackButton onClick={onChangeTab}>
        <LockIcon />
        &nbsp;{changeForm === 1 ? (<>Back to login</>) : (<>Back to register</>)}
      </BackButton>
    </AuthFormLayout>
  );
};

export default ForgotPassword;
