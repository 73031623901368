import {PayloadAction} from "@reduxjs/toolkit";
import {CREATE_PROFILE_REVIEW, EDIT_PROFILE_REVIEW,UPDATE_REVIEWSORT} from "../../actionType";

interface IReviewTextReducer {
    refreshList: boolean,
}

const initialState: IReviewTextReducer = {
    refreshList: false,
}

const reviewTextReducer = (state = initialState, action: PayloadAction<any>): IReviewTextReducer => {
    switch (action.type){
        case CREATE_PROFILE_REVIEW:
            return {
                ...state,
                refreshList: !state.refreshList,
            }
        case EDIT_PROFILE_REVIEW:
            return {
                ...state,
                refreshList: !state.refreshList,
            }
		case UPDATE_REVIEWSORT:
			return {
				...state,
				refreshList: !state.refreshList,
			}
        default:
            return state;
    }
}

export default reviewTextReducer;