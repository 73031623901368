import { forwardRef } from "react";
import styled from "styled-components";
import mediaQueries from "../../utility/responsive";

const CanvasStyled = styled.canvas`
  width: 146px;
  height: 42px;

  ${mediaQueries.nav}{
    width: 136px;
    height: 42px;
  }
`;

export interface CaptchaProps {
  // onCaptchaChange: (text: string) => void //function
  // onRightCaptchaChange: (text: string) => void;
  isCaptchaError: Boolean;
}

const Captcha = forwardRef<HTMLCanvasElement, CaptchaProps>((props, ref) => {
  const {
    isCaptchaError,
  } = props

  return (
    <CanvasStyled ref={ref} id="valicode"></CanvasStyled>
  );
})

export default Captcha;