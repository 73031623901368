import { Hidden } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const HeaderBackground = styled.div`
  background-color: #000030;
  width: 100%;
  padding: 14px 16px;
  height: 60px;
  color: white;
`;

interface HeaderProps {
  onToggleMenuBar: () => void;
}

const Header: FC<HeaderProps> = ({ onToggleMenuBar }) => {
  return (
    <HeaderBackground>
      <Hidden mdDown implementation="css">
        <HeaderDesktop />
      </Hidden>
      <Hidden mdUp implementation="css">
        <HeaderMobile onToggleMenuBar={onToggleMenuBar} />
      </Hidden>
    </HeaderBackground>
  );
};

export default Header;
