import "./App.css";
import isEmpty from "lodash/isEmpty";
import { Drawer, Grid, Hidden, ThemeProvider } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";

import Router from "./router/Router";
import { useAppSelector } from "./utility/hooks/useSelector";
import theme from "./constant/lightTheme";
import NavBar from "./components/NavBar";
import ReviewSourceDetailModal from "./components/Modal/ReviewSourceDetailModal";
import ReviewTextDetailModal from "./components/Modal/ReviewTextDetailModal";
import { useDispatch } from "react-redux";
import { handleGetUserData } from "./redux/action/auth";
import Header from "./components/Header";
import { useLocation } from 'react-router-dom';

const AssignUserModal = lazy(
  () => import("./components/Modal/AssignUserModal/AssignUserModal")
);
const DeleteModal = lazy(() => import("./components/Modal/DeleteModal/index"));
const AddOrEditUserModal = lazy(
  () => import("./components/Modal/AddOrEditUserModal")
);
const PaymentDetailModal = lazy(
  () => import("./components/Modal/PaymentDetailModal")
);

function App(props: any) {
  const { window } = props;
  const location = useLocation();
  const { access } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [openMobile, setOpenMobile] = useState<boolean>(false);
  const { pathname } = location;

  useEffect(() => {
    (async () => {
      await dispatch(handleGetUserData());
    })();
  }, []);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setOpenMobile(!openMobile);
  };

  return (
    <ThemeProvider theme={theme}>
      {isEmpty(access) ? (
        <Grid sx={{ "& > div": { minHeight: "100vh" } }}>
          <Router />
        </Grid>
      ) : pathname === "/success" ? (
        <Grid container>
          <Router />
        </Grid>
      ) : (
        <Grid container>
          <Hidden mdUp implementation="js">
            <Drawer
              container={container}
              variant="temporary"
              open={openMobile}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                width: 250,
                minHeight: "calc(100vh - 60px)",
                "& .MuiDrawer-paper": { width: 250 },
              }}
            >
              <NavBar />
            </Drawer>
          </Hidden>
          <Grid item sx={{ width: '100%'}}>
            <Grid container>
              <Grid item xs={12}>
                <Header onToggleMenuBar={handleDrawerToggle} />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Hidden mdDown implementation="js">
                    <Grid
                      item
                      style={{ maxWidth: 250, minHeight: "calc(100vh - 60px)" }}
                    >
                      <NavBar />
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#F5F6FA",
                      width: { xs: "100%", md: "calc(100% - 250px)" },
                    }}
                  >
                    <Router />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Suspense fallback={<></>}>
            <AssignUserModal />
            <DeleteModal />
            <AddOrEditUserModal />
            <PaymentDetailModal />
            <ReviewSourceDetailModal />
            <ReviewTextDetailModal />
          </Suspense>
        </Grid>
      )}
    </ThemeProvider>
  );
}

export default App;
