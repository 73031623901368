const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.01759 11.1645C4.0676 6.79251 8.02225 4.5 11.9841 4.5C16.6371 4.5 20.6453 7.53129 23.0101 11.1836L23.0112 11.1853C23.1677 11.4285 23.2509 11.7115 23.2509 12.0007C23.2509 12.2893 23.168 12.5719 23.012 12.8147C20.6493 16.5141 16.6671 19.5 11.9841 19.5C7.2513 19.5 3.3458 16.5203 0.988892 12.8317C0.828743 12.583 0.745679 12.2925 0.750173 11.9968C0.754679 11.7002 0.846976 11.4117 1.0154 11.1676L1.01759 11.1645ZM2.25001 12.0196L2.25208 12.0228C4.42901 15.4304 7.91813 18 11.9841 18C16.0045 18 19.5689 15.4195 21.7484 12.0065L21.7498 12.0043C21.7505 12.0032 21.7509 12.002 21.7509 12.0007C21.7509 12.0002 21.7508 11.9996 21.7507 11.9991C21.7505 11.9985 21.7503 11.9979 21.7499 11.9973C19.5621 8.61915 15.9686 6 11.9841 6C8.63995 6 5.10025 7.93555 2.25001 12.0196Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12Z"
        fill="#333333"
      />
    </svg>
  );
};

export default EyeIcon;
