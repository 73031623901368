import { Grid, Typography } from "@mui/material";
import theme from "../../constant/lightTheme";

const PageLayout = ({
  children,
  title,
  subTitle,
  alignCenter = false,
  isReviewSource,
}: {
  children: Array<JSX.Element> | JSX.Element | string;
  title?: any;
  subTitle?: React.ReactNode;
  alignCenter?: boolean;
  isReviewSource?: boolean;
}) => {
  return (
    <Grid container justifyContent={"center"} style={{ margin: "32px 0" }}>
      <Grid item xs={11}>
        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            xs={12}
            style={{ textAlign: alignCenter ? "center" : "unset" }}
          >
            <Typography variant={"h4"} color={theme.custom.color.primary} style={{fontSize: isReviewSource ? '18px' : '30px'}}>{title}</Typography>
            {subTitle && <Typography variant={"body1"} style={{ marginTop: 12}}>{subTitle}</Typography>}
          </Grid>
          {/* <Grid item>button</Grid> */}
          <Grid item xs={12} style={{ marginTop: 32 }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageLayout;
