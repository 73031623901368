import {PayloadAction} from "@reduxjs/toolkit";
import {
    DELETE_PROFILE,
    GET_PROFILE_DETAIL,
    GET_PROFILE_LIST,
    REFRESH_PROFILE_LIST,
    RESET_PROFILE_DETAIL,
    GET_PROFILE_ME,
    GET_PROFILE_ME_FAIL,
    ASSGIN_USER_NEW_PROFILE,
    CREATE_PROFILE,
    CLEAR_PROFILE,
    ASSIGN_USER_NEW_PROFILE
} from "../../actionType";
import {IProfile} from "../../../interface/IProfile";

interface profileReducerType {
    profileList: Array<any>;
    profileListTotal: number;
    updateProfieList: boolean;
    profileDetail: any;
    refreshProfileList: boolean;
    companyMe: any;
    assignUserId: number | null;
    assignUserTemp: any;
    refreshProfile: boolean;
	urlCompany: any
}

const initialState: profileReducerType = {
    profileList: [],
    profileListTotal: 0,
    updateProfieList: false,
    profileDetail: {},
    refreshProfileList: false,
    companyMe: {},
    assignUserId: 0,
    assignUserTemp: {},
    refreshProfile: false,
	urlCompany: {},
}

const profileReducer = (state = initialState, action: PayloadAction<any>): profileReducerType => {
    switch (action.type) {
        case GET_PROFILE_LIST:
            return {
                ...state,
                profileList: action.payload.results,
                profileListTotal: action.payload.count,
            }
        case DELETE_PROFILE:
            return {
                ...state,
                updateProfieList: !state.updateProfieList,
            }
        case GET_PROFILE_DETAIL:
            return {
                ...state,
                profileDetail: action.payload
            }
        case RESET_PROFILE_DETAIL:
            return {
                ...state,
                profileDetail: {}
            }
        case REFRESH_PROFILE_LIST:
            return {
                ...state,
                refreshProfileList: !state.refreshProfileList,
            }
        case GET_PROFILE_ME:
            return {
                ...state,
                companyMe: action.payload,
				urlCompany: action.payload
            }
        case ASSGIN_USER_NEW_PROFILE:
            return {
                ...state,
                assignUserId: action.payload,
            }
        case ASSIGN_USER_NEW_PROFILE:
            return {
                ...state,
                assignUserTemp: action.payload,
            }
        case CREATE_PROFILE:
            return {
                ...state,
                refreshProfile: true,
            }
        case CLEAR_PROFILE:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}

export default profileReducer;