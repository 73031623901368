import { useRoutes } from "react-router-dom";
import { Routes as RoutesList } from "./routes";
import { Suspense } from "react";

const Router = () => {
  const routing = useRoutes(RoutesList());

  return <Suspense fallback={<></>}>{routing}</Suspense>;
};

export default Router;
