import { combineReducers } from "redux";

import auth from "./auth";
import profile from "./profile";
import modal from "./modal";
import user from "./user";
import reviewSource from "./reviewSource";
import sourceReview from "./source review";
import widget from './widgets';
import sourceSocial from "./source social";
import platforms from "./platforms";
import seals from './seals';
import changelog from './changeLog';
import reviewText from "./reviewText";
import dashboard from "./dashboard";
import payment from "./paymentHistory";

const rootReducer = combineReducers({
  auth: auth,
  profile: profile,
  modal: modal,
  user: user,
  resource: reviewSource,
  sourceReview: sourceReview,
  widget: widget,
  sourceSocial: sourceSocial,
  platforms: platforms,
  seals: seals,
  changelog: changelog,
  reviewText: reviewText,
  dashboard: dashboard,
  payment: payment
});

export default rootReducer;
