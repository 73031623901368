const EyeCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.2501 21.0001C20.1516 21.0003 20.054 20.9809 19.963 20.9432C19.872 20.9054 19.7894 20.8501 19.7199 20.7803L3.21994 4.28025C3.08522 4.13845 3.01123 3.94964 3.01373 3.75407C3.01624 3.5585 3.09504 3.37164 3.23334 3.23334C3.37164 3.09504 3.5585 3.01624 3.75407 3.01373C3.94964 3.01123 4.13845 3.08522 4.28025 3.21994L20.7803 19.7199C20.8851 19.8248 20.9564 19.9584 20.9854 20.1039C21.0143 20.2493 20.9994 20.4001 20.9427 20.5371C20.8859 20.6741 20.7899 20.7912 20.6666 20.8736C20.5433 20.956 20.3984 21 20.2501 21.0001Z"
        fill="#333333"
      />
      <path
        d="M11.984 17.9998C10.0392 17.9998 8.16371 17.4242 6.40965 16.2889C4.81261 15.2576 3.37496 13.7806 2.25183 12.0233V12.0195C3.18652 10.6803 4.21027 9.54779 5.30996 8.63466C5.31991 8.62635 5.32802 8.61606 5.33379 8.60444C5.33956 8.59283 5.34285 8.58015 5.34347 8.56719C5.34409 8.55424 5.34201 8.5413 5.33737 8.5292C5.33273 8.51709 5.32563 8.50607 5.31652 8.49685L4.38277 7.56451C4.36618 7.54778 4.34389 7.53791 4.32035 7.53687C4.29682 7.53582 4.27375 7.54368 4.25574 7.55888C3.08761 8.54326 2.0034 9.75076 1.01761 11.1645C0.848014 11.4079 0.754608 11.6963 0.749266 11.9929C0.743924 12.2895 0.826888 12.5811 0.987615 12.8304C2.22558 14.7678 3.81886 16.3995 5.59449 17.5484C7.59371 18.8436 9.74527 19.4998 11.984 19.4998C13.1924 19.4961 14.3923 19.2969 15.5371 18.9101C15.5522 18.905 15.5658 18.8961 15.5765 18.8843C15.5872 18.8725 15.5947 18.8581 15.5983 18.8426C15.602 18.8271 15.6015 18.8109 15.5972 18.7955C15.5928 18.7802 15.5845 18.7662 15.5732 18.755L14.5617 17.7434C14.5384 17.7207 14.5096 17.7044 14.4781 17.6962C14.4466 17.688 14.4136 17.6881 14.3821 17.6965C13.5987 17.8983 12.793 18.0002 11.984 17.9998Z"
        fill="#333333"
      />
      <path
        d="M23.0081 11.1844C21.7678 9.26625 20.1586 7.63688 18.3548 6.47203C16.3594 5.18203 14.1562 4.5 11.9841 4.5C10.7884 4.50212 9.60172 4.70543 8.47358 5.10141C8.45855 5.10664 8.4451 5.11561 8.4345 5.12747C8.42389 5.13934 8.41648 5.15371 8.41296 5.16923C8.40945 5.18475 8.40994 5.20091 8.41439 5.21619C8.41885 5.23147 8.42712 5.24536 8.43843 5.25656L9.44858 6.26672C9.4721 6.28984 9.5013 6.30634 9.53323 6.31457C9.56516 6.3228 9.5987 6.32246 9.63046 6.31359C10.3978 6.10602 11.1891 6.00058 11.9841 6C13.8914 6 15.7612 6.58266 17.5411 7.73438C19.1681 8.78438 20.6226 10.26 21.7486 12C21.7494 12.0011 21.7499 12.0024 21.7499 12.0037C21.7499 12.0051 21.7494 12.0064 21.7486 12.0075C20.9313 13.2942 19.9171 14.4446 18.743 15.4167C18.7329 15.425 18.7247 15.4353 18.7188 15.4469C18.713 15.4586 18.7096 15.4713 18.7089 15.4843C18.7083 15.4973 18.7103 15.5103 18.715 15.5225C18.7196 15.5347 18.7268 15.5457 18.7359 15.555L19.6687 16.4873C19.6852 16.504 19.7074 16.5139 19.7308 16.515C19.7543 16.5161 19.7773 16.5084 19.7953 16.4934C21.0487 15.4381 22.1337 14.1975 23.0128 12.8147C23.1682 12.571 23.2503 12.2878 23.2495 11.9988C23.2487 11.7098 23.1649 11.4272 23.0081 11.1844Z"
        fill="#333333"
      />
      <path
        d="M12 7.5C11.6629 7.49982 11.3269 7.53756 10.9983 7.6125C10.9817 7.61595 10.9663 7.62383 10.9538 7.63531C10.9414 7.64679 10.9322 7.66144 10.9274 7.6777C10.9226 7.69395 10.9223 7.71121 10.9265 7.72763C10.9308 7.74405 10.9393 7.75901 10.9514 7.77094L16.2291 13.0472C16.241 13.0592 16.256 13.0678 16.2724 13.072C16.2888 13.0763 16.3061 13.076 16.3223 13.0711C16.3386 13.0663 16.3532 13.0572 16.3647 13.0447C16.3762 13.0323 16.3841 13.0169 16.3875 13.0003C16.5378 12.3413 16.5376 11.6568 16.3871 10.9979C16.2366 10.3389 15.9395 9.72227 15.518 9.19385C15.0965 8.66542 14.5613 8.23874 13.9523 7.9455C13.3432 7.65226 12.676 7.49999 12 7.5Z"
        fill="#333333"
      />
      <path
        d="M7.77091 10.953C7.75899 10.941 7.74402 10.9324 7.7276 10.9282C7.71118 10.9239 7.69392 10.9242 7.67767 10.9291C7.66141 10.9339 7.64676 10.943 7.63528 10.9555C7.6238 10.9679 7.61592 10.9833 7.61247 10.9999C7.44248 11.7426 7.46382 12.5162 7.67451 13.2484C7.88519 13.9805 8.27832 14.6472 8.81704 15.1859C9.35577 15.7247 10.0225 16.1178 10.7546 16.3285C11.4868 16.5392 12.2604 16.5605 13.0031 16.3905C13.0197 16.3871 13.0351 16.3792 13.0475 16.3677C13.06 16.3562 13.0691 16.3416 13.0739 16.3253C13.0788 16.3091 13.0791 16.2918 13.0748 16.2754C13.0706 16.259 13.062 16.244 13.05 16.2321L7.77091 10.953Z"
        fill="#333333"
      />
    </svg>
  );
};

export default EyeCloseIcon;
