import React from 'react'

const Email = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7566 20.7863H4.37574C3.24487 20.7863 2.32812 19.8786 2.32812 18.7589V6.50671C2.37584 5.42162 3.27876 4.56653 4.37574 4.56756H20.7566C21.8875 4.56756 22.8042 5.47523 22.8042 6.5949V18.7589C22.8042 19.8786 21.8875 20.7863 20.7566 20.7863ZM4.37574 8.48843V18.7589H20.7566V8.48843L12.5662 13.8933L4.37574 8.48843ZM5.19478 6.5949L12.5662 11.4605L19.9376 6.5949H5.19478Z" fill="white"/>
    </svg>
    
  )
}

export default Email
