import { PayloadAction } from "@reduxjs/toolkit";
import { GET_PLATFORM_LIST } from "../../actionType";

interface platformsReducerType {
    platformList: any[],
}

const initialState: platformsReducerType = {
    platformList: [],
}

const sourceReviewReducer = (state = initialState, action: PayloadAction<any>): platformsReducerType => {
    switch (action.type) {
        case GET_PLATFORM_LIST:
            action?.payload?.results?.sort(
                (a: any, b: any) => a?.name?.localeCompare(b?.name ?? '')
            ); // sort alpha beta

            return {
                ...state,
                platformList: action.payload.results,
            }
        default:
            return state;
    }
}

export default sourceReviewReducer;