import { FormEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { LogoLight } from "../Icon";
import { Typography } from "@mui/material";
import { PageLayout } from "../../components/Layout";
import mediaQueries from "../../utility/responsive";

const Layout = styled.form`
  width: 504px;
 
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  ${mediaQueries.nav}{
	width: 100%;
	border-radius: 0px;
  }
`;

const AuthForm = ({
	children,
	title,
	subTitle,
	onSubmit,
}: {
	children: any;
	title?: string;
	subTitle?: string;
	onSubmit?: FormEventHandler<HTMLFormElement>;
}) => {
	return (
		<Layout onSubmit={onSubmit}>
			<img src="/assets/default-logo/logo-dark.png" width="250" height="50" style={{marginBottom: 40, marginTop: 10}}/>
			
			{title && (
				<Typography
					variant={"h5"}
					style={{ marginBottom: subTitle ? 12 : 32, color: "#000030" }}
				>
					{title}
				</Typography>
			)}
			{subTitle && (
				<Typography
					variant={"body1"}
					style={{ marginBottom: 32, color: "#536283" }}
					component={"p"}
				>
					{subTitle}
				</Typography>
			)}
			{children}
		</Layout>
	);
};

export default AuthForm;
