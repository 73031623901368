import { PayloadAction } from "@reduxjs/toolkit";
import { PAYMENT_COMPLETE } from "../../../constant/paymentStatus";
import { DELETE_PAYMENT, GET_PAYMENT_DETAIL, GET_PAYMENT_LIST, GET_PAYMENT_LIST_FAIL } from "../../actionType";

interface paymentReducerType {
    paymentList: Array<any>;
    invoiceList: Array<any>;
    paymentTotal: number;
    updatePaymentList: boolean;
    paymentDetail: any;
    totalAmount: number;
}

const initialState: paymentReducerType = {
    paymentList: [],
    invoiceList: [],
    paymentTotal: 0,
    updatePaymentList: false,
    paymentDetail: {},
    totalAmount: 0,
}

const paymentReducer = (state = initialState, action: PayloadAction<any>): paymentReducerType => {
    switch (action.type) {
        case GET_PAYMENT_LIST:
            return {
                ...state,
                paymentList: action.payload.results,
                invoiceList: action.payload.results.filter((payment: any) => payment.status === PAYMENT_COMPLETE),
                paymentTotal: action.payload.count,
                totalAmount: action.payload.total_amount
            }
        case DELETE_PAYMENT:
            return {
                ...state,
                updatePaymentList: !state.updatePaymentList,
            }
        case GET_PAYMENT_DETAIL:
            return {
                ...state,
                paymentDetail: action.payload
            }
        // case CLOSE_ADD_OR_EDIT_USER_MODAL:
        //     return {
        //         ...state,
        //         userDetail: {},
        //         updateUserList: !state.updateUserList,
        //     }
        default:
            return state;
    }
}

export default paymentReducer;