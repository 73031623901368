import {Grid, ListItemButton, ListItemText, MenuList, Typography} from "@mui/material";
import {styled} from "@mui/system";
import {NavMenuAdmin, NavMenuUser} from "../../constant/NavMenu";
import MenuItem from "./MenuItem";
import {useDispatch} from "react-redux";
import {handleLogout} from "../../redux/action/auth";
import {useAppSelector} from "../../utility/hooks/useSelector";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {useNavigate} from "react-router-dom";

const NavBackground = styled(Grid)(({theme}) => ({
    background: "white",
    width: "100%",
    height: "100%",
    padding: "24px 0",
}));

const NavBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()

    const [indexSelected, setIndexSelected] = useState(-1);

    const userData = useAppSelector((state) => state.auth);
    const handleClick = () => {
        dispatch(handleLogout());
        navigate("/login");
    }

    const handleChangedMenu = (index: number)=>{
        setIndexSelected(index);
    }

    useEffect(()=> {
        var menus;

        if(userData?.userData?.is_superuser === true){
            menus = NavMenuAdmin;
        }else{
            menus = NavMenuUser;
        }

        var i;
             i = menus.findIndex((menu)=> menu.href === location.pathname);
             if(i === -1){
                i = menus.findIndex((menu)=> menu.href!=null && menu.href !== '/' && location.pathname.includes(menu.href));
             }

             setIndexSelected(i);
    }, [userData, location.pathname]);

    return (
        <NavBackground
            container
            justifyContent={"space-between"}
            alignContent={"space-between"}
        >
            <Grid item xs={12}>
                {userData?.userData?.is_superuser === true ? (
                    <MenuList>
                        {NavMenuAdmin.map((item, index) => (
                            <MenuItem menu={item} key={index} index= {index} indexSelected={indexSelected} onClicked={handleChangedMenu}/>
                        ))}
                        <ListItemButton onClick={handleClick} sx={{
                            "& .MuiTypography-root": {
                                fontWeight: 600,
                                paddingLeft: "28px"
                            },
                            paddingLeft: "24px"
                        }}>
                            <ListItemText>Logout</ListItemText>
                        </ListItemButton>
                    </MenuList>
                ) : (
                    <MenuList>
                        {NavMenuUser.map((item, index) => (
                            <MenuItem menu={item} key={index} index= {index} indexSelected={indexSelected} onClicked={handleChangedMenu}/>
                        ))}
                        <ListItemButton onClick={handleClick} sx={{
                            "& .MuiTypography-root": {
                                fontWeight: 600,
                                paddingLeft: "28px"
                            },
                            paddingLeft: "24px"
                        }}>
                            <ListItemText>Logout</ListItemText>
                        </ListItemButton>
                    </MenuList>
                )}
 
            </Grid>
            <Grid item xs={12} style={{paddingLeft: 24}}>
                <Typography variant={"caption"} component={"p"}>
                    © 2022 VerifyTrusted.&nbsp;{process.env.REACT_APP_VERSION_BUILD}
                </Typography>
            </Grid>
        </NavBackground>
    );
};

export default NavBar;
