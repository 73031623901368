import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalTitle = ({
  children,
  onClose,
  style,
  ...other
}: {
  children: React.ReactNode;
  onClose: () => void;
  style?: any;
}) => {
  return (
    <DialogTitle {...other} style={{ padding: "0 0 16px", fontSize: "1.25rem", ...style }}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose} 
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            color: (theme) => theme.custom.color.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTitle;
