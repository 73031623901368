const breakpointMap: { [key: string]: number } = {
    xs: 370,
    sm: 576,
    md: 852,
    lg: 968,
    xl: 1080,
    xxl: 1200,
    xxxl: 1312,
};

type MediaQueries = {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
    nav: string
    xxxl: string
}

const mediaQueries: MediaQueries = {
    xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
    sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
    md: `@media screen and (min-width: ${breakpointMap.md}px)`,
    lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
    xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
    xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
    nav: `@media screen and (max-width: ${breakpointMap.lg}px)`,
    xxxl: `@media screen and (max-width: ${breakpointMap.xxxl}px)`,
};

export default mediaQueries;