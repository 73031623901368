import { axiosClient } from "../../../@core/auth/jwt/jwtService";
import {
  ChangePasswordRequest,
  ConfirmResetPasswordRequest,
  Cookie,
  User,
  ValidateTokenRequest,
} from "../../../interface";
import { getCookies } from "../../../utility/common/getCookies";

export const login = (data: User) => {
  return axiosClient.post("/api/auth/token/", data, { withToken: false });
};

export const register = (data: User) => {
  return axiosClient.post("/api/users/register/", data, { withToken: false });
};

export const logout = (refreshToken: string) => {
  return axiosClient.post(
    "/api/auth/logout/",
    { refresh: refreshToken },
    { withToken: true }
  );
};

export const getUserData = () => {
    const cookieObj: Cookie = getCookies();
  // if accessToken is invalid -> use refresh token
  return axiosClient.get(
    "/api/me/",
    // { withToken: true },
    {
      headers: {
        Authorization: `Bearer ${cookieObj.accessToken}`,
      },
    }
  );
};

export const resetPassword = (email: string) => {
  return axiosClient.post(
    "/api/password_reset/",
    { email: email },
    { withToken: false }
  );
};

export const confirmResetPassword = (data: ConfirmResetPasswordRequest) => {
  return axiosClient.post("/api/password_reset/confirm/", data, {
    withToken: false,
  });
};

export const validateToken = (data: ValidateTokenRequest) => {
  return axiosClient.post("/api/password_reset/validate_token/", data);
};

export const changePassword = (data: ChangePasswordRequest) => {
  return axiosClient.post("/api/me/change_password/", data, {
    withToken: true,
  });
};

//Account Setting

export const updateAccountSetting = (id: number, form: FormData) => {
  return axiosClient.patch(`api/me/${id}/`, form, { withToken: true });
};

export const activeAccount = (token: string) => {
  return axiosClient.get(`api/auth/email-verify/?token=${token}`, {
    withToken: false,
  });
};

export const checkRegister = (token: any) => {
  return axiosClient.get(`api/users/register/check-register/?token=${token}`, {
    withToken: false,
  });
};

export const deactive = () => {
  return axiosClient.post(`api/me/deactive/`, {}, { withToken: true });
};
