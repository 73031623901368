import { PayloadAction } from "@reduxjs/toolkit";
import {
	CREATE_WIDGETS, CREATE_WIDGETS_FAIL,
	GET_ALL_WIDGETS_FAIL, GET_ALL_WIDGETS,
	GET_WIDGET,
	GET_WIDGET_FAIL,
	GET_WIDGET_COMPANY_ID,
	GET_WIDGET_COMPANY_ID_FAIL,
	EDIT_WIDGET_SUCCESS,EDIT_WIDGET_FAIL,
	UPDATE_WIDGET_SUCCESS
} from "../../actionType";

interface widgetReducerType {
	isLoading: boolean;
	widgetsList: Array<any>
	widgetCreate: Array<any>
	widgetDetail: Array<any>
	widgetCompanyDetail: Array<any>
	widgetID: string
	isSuccess: boolean
}

const initialState: widgetReducerType = {
	isLoading: false,
	widgetsList: [],
	widgetCreate: [],
	widgetDetail: [],
	widgetCompanyDetail: [],
	widgetID: '',
	isSuccess: false,
}

const widgetsReducer = (state = initialState, action: PayloadAction<any>): widgetReducerType => {
	switch (action.type) {
		case CREATE_WIDGETS:
			return {
				...state,
				widgetID: action.payload.id,
				isSuccess: true
			}
		case CREATE_WIDGETS_FAIL:
			return {
				...state,
				isLoading: false,
			}
		case GET_ALL_WIDGETS:
			return {
				...state,
				widgetsList: action.payload,
			}
		case GET_WIDGET:
			return {
				...state,
				widgetDetail: action.payload,
			}
		case GET_WIDGET_COMPANY_ID: 
			return {
				...state,
				widgetCompanyDetail: action.payload
			}
		case EDIT_WIDGET_SUCCESS: 
			return {
				...state,
				isSuccess: true
			}
		case UPDATE_WIDGET_SUCCESS:
			return {
				...state,
				isSuccess: true
			}
		default:
			return state;
	}
}
export default widgetsReducer;