import { Breakpoint, Dialog, DialogContent, styled } from "@mui/material";
import ModalTitle from "./ModalTitle";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    padding: 32,
    backgroundColor: "#F5F6FA",
    borderRadius: 8,
    width: 630,
  },
}));

const PaymentHistoryModal = ({
  open,
  onClose,
  title,
  children,
  fullWidth = false,
  maxWidth = false,
  style,
}: {
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
  style?: any;
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <ModalTitle style={style} onClose={onClose}>
        {title}
      </ModalTitle>
      <DialogContent style={{ padding: "0 0 20px" }}>{children}</DialogContent>
    </StyledDialog>
  );
};

export default PaymentHistoryModal;
