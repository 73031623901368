export const errorMessages = {
    MSG001: 'Full name is required.',
    MSG002: 'Full name can only include letters, numbers',
    MSG003: 'Full name must be 6-32 characters.',
    MSG004: 'Company name is required.',
    MSG005: 'Email address is required.',
    MSG006: 'Invalid email address.',
    MSG007: 'Password is required.',
    MSG008: 'This password is too short. It must contain at least 6 characters.',
    MSG009: 'The special character do not accept.',
    MSG010: 'Password must contain at least 1 digit.',
    MSG011: 'Password must contain at least 1 lowercase letter.',
    MSG012: 'Password must contain at least 1 uppercase letter.',
    MSG000: 'Password update successfully.',
    MSG013: 'Website URL is required.',
    MSG014: 'Invalid Website URL.',
    MSG015: 'The company profile has already claimed!',
    MSG017: 'Email has already existed.'
}