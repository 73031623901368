import React from "react";

const Password = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7997 8.41831H16.7759V6.54506C16.7759 5.49359 16.3444 4.48519 15.5764 3.74168C14.8084 2.99818 13.7668 2.58049 12.6807 2.58049C11.5946 2.58049 10.5529 2.99818 9.78492 3.74168C9.01691 4.48519 8.58545 5.49359 8.58545 6.54506V8.41831H7.56165C6.74706 8.41831 5.96583 8.73158 5.38983 9.28921C4.81383 9.84684 4.49023 10.6031 4.49023 11.3917V19.3209C4.49023 20.1095 4.81383 20.8658 5.38983 21.4234C5.96583 21.981 6.74706 22.2943 7.56165 22.2943H17.7997C18.6143 22.2943 19.3955 21.981 19.9715 21.4234C20.5475 20.8658 20.8711 20.1095 20.8711 19.3209V11.3917C20.8711 10.6031 20.5475 9.84684 19.9715 9.28921C19.3955 8.73158 18.6143 8.41831 17.7997 8.41831ZM10.6331 6.54506C10.6193 6.00518 10.827 5.48201 11.2108 5.09006C11.5945 4.69811 12.1231 4.46931 12.6807 4.45375C13.2383 4.46931 13.7668 4.69811 14.1506 5.09006C14.5343 5.48201 14.7421 6.00518 14.7283 6.54506V8.41831H10.6331V6.54506ZM18.8235 19.3209C18.8235 19.5837 18.7156 19.8358 18.5236 20.0217C18.3316 20.2076 18.0712 20.312 17.7997 20.312H7.56165C7.29012 20.312 7.02971 20.2076 6.83771 20.0217C6.64571 19.8358 6.53784 19.5837 6.53784 19.3209V11.3917C6.53784 11.1289 6.64571 10.8768 6.83771 10.6909C7.02971 10.505 7.29012 10.4006 7.56165 10.4006H17.7997C18.0712 10.4006 18.3316 10.505 18.5236 10.6909C18.7156 10.8768 18.8235 11.1289 18.8235 11.3917V19.3209Z"
        fill="white"
      />
      <path
        d="M12.6818 12.3824C12.0743 12.3824 11.4805 12.5568 10.9754 12.8836C10.4703 13.2103 10.0766 13.6747 9.84415 14.218C9.61168 14.7613 9.55086 15.3592 9.66937 15.936C9.78788 16.5127 10.0804 17.0426 10.5099 17.4584C10.9395 17.8742 11.4868 18.1574 12.0826 18.2722C12.6784 18.3869 13.2959 18.328 13.8571 18.103C14.4184 17.8779 14.8981 17.4968 15.2356 17.0078C15.573 16.5188 15.7532 15.944 15.7532 15.3559C15.7532 14.5673 15.4296 13.811 14.8536 13.2533C14.2776 12.6957 13.4964 12.3824 12.6818 12.3824ZM12.6818 16.347C12.4793 16.347 12.2813 16.2889 12.113 16.18C11.9446 16.0711 11.8134 15.9163 11.7359 15.7352C11.6584 15.5541 11.6381 15.3548 11.6776 15.1625C11.7171 14.9702 11.8146 14.7936 11.9578 14.655C12.101 14.5164 12.2834 14.422 12.482 14.3838C12.6806 14.3455 12.8865 14.3652 13.0736 14.4402C13.2606 14.5152 13.4205 14.6422 13.533 14.8052C13.6455 14.9682 13.7056 15.1598 13.7056 15.3559C13.7056 15.6187 13.5977 15.8708 13.4057 16.0567C13.2137 16.2426 12.9533 16.347 12.6818 16.347Z"
        fill="white"
      />
    </svg>
  );
};

export default Password;
