const ChangeLogIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4002 13H14.0002V14.3334H10.0002V10.3334H11.3335V12.1534C12.5535 11.1734 13.3335 9.68003 13.3335 8.00003C13.3335 5.2867 11.2935 3.04003 8.66683 2.71337V1.3667C12.0335 1.70003 14.6668 4.54003 14.6668 8.00003C14.6668 9.99337 13.7868 11.78 12.4002 13ZM2.66683 8.00003C2.66683 6.32003 3.44683 4.82003 4.66683 3.8467V5.6667H6.00016V1.6667H2.00016V3.00003H3.60016C2.8879 3.62382 2.31718 4.39264 1.92626 5.25498C1.53534 6.11731 1.33324 7.05323 1.3335 8.00003C1.3335 11.46 3.96683 14.3 7.3335 14.6334V13.2867C4.70683 12.96 2.66683 10.7134 2.66683 8.00003Z"
                fill="#333333"/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.13638 5.08593C9.19268 5.02963 9.26972 5 9.34379 5C9.42083 5 9.4949 5.02667 9.55416 5.08593L10.2475 5.77926C10.363 5.89481 10.363 6.08148 10.2475 6.19703L9.70527 6.73925L8.59416 5.62815L9.13638 5.08593ZM8.27703 5.94512L5 9.22215V10.3333H6.11111L9.38813 7.05623L8.27703 5.94512Z"
                fill="#333333"/>
        </svg>
    );
};

export default ChangeLogIcon;