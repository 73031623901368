const IconRegisterV = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 1362.000000 1362.000000"
        preserveAspectRatio="xMidYMid meet"
		fill="none"
		style={{ ...style }}
      >
        <g
          transform="translate(0.000000,1362.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M13110 12078 c-1432 -998 -2330 -1651 -5025 -3652 -2221 -1649 -3053
		-2259 -4050 -2971 -886 -632 -1496 -1052 -1540 -1061 -68 -12 -145 50 -145
		118 0 15 146 933 325 2039 179 1106 325 2021 325 2033 0 31 -19 46 -57 46 -21
		0 -36 -6 -42 -17 -5 -10 -608 -1643 -1340 -3630 -1021 -2772 -1331 -3622
		-1331 -3656 0 -111 134 -194 231 -143 15 7 476 396 1025 863 1529 1299 2523
		2142 6164 5228 1991 1687 3088 2617 4815 4084 385 326 717 610 738 629 41 39
		49 79 21 106 -24 24 -64 18 -114 -16z"
          />
        </g>
      </svg>
    </>
  );
};

export default IconRegisterV;
