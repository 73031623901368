import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import rootReducer from "../reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// ** init middleware
const middleware = [thunk, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const storeDev = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

const storeProduct = createStore(
    rootReducer,
    {},
    compose(applyMiddleware(...middleware))
  );

export const configureStore = process.env.NODE_ENV === "production" ? storeProduct : storeDev;

export type RootState = ReturnType<typeof configureStore.getState>;
export type AppDispatch = typeof configureStore.dispatch;
