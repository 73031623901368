import { ReloadOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Captcha from "../../../components/Captcha";
import { AuthFormLayout, AuthPageLayout } from "../../../components/Layout";
import { User } from "../../../interface/User";
import { handleLogin, handleSetLoading } from "../../../redux/action/auth";
import { getImgValiCode } from "../../../utility/common/getImgValidCode";
import { useAppSelector } from "../../../utility/hooks/useSelector";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styled from "styled-components";
import {
  AuthForm,
  AuthPage,
  CaptchaStyled,
  Error,
  Input,
  Navigation,
  SubmitButton,
  Title,
  SignInButton,
} from "../Auth";
import ForgotPassword from "../ForgotPassword";
import _ from "lodash";
import {
  Grid,
  TextField,
  Typography,
  Link,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { EyeIcon } from "../../../components/Icon";
import { EyeCloseIcon } from "../../../components/Icon";
import { handleGetUserList } from "../../../redux/action/user";
import mediaQueries from "../../../utility/responsive";
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is require")
    .email("Invalid email address"),
  password: yup.string().required("Password is require"),
});

const TextBottom = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #536283;

  ${mediaQueries.nav} {
    text-align: start;
  }
`;

const ReloadOutlinedStyled = styled(ReloadOutlined)`
  width: 32px;
  height: 32px;
  padding: 6px;
  color: #697693;
  margin-top: 10px;
  margin-right: 16px;
  margin-left: 25px;

  ${mediaQueries.nav} {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

const CapchaTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #536283;
  }

  ${mediaQueries.nav} {
    .MuiOutlinedInput-root {
      font-size: 14px;
    }

    .MuiInputLabel-root {
      font-size: 12px;
    }
  }
`;

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.auth.isLoading);
  const accessToken = useAppSelector((state) => state.auth.access);
  const failLogin = useAppSelector((state) => state.auth.failLogin);
  const userData = useAppSelector((state) => state.auth);
  const [myCaptcha, setMyCaptcha] = useState(""); //captcha get form input
  const [rightCode, setRightCode] = useState(""); //captcha valid
  const [isCaptchaError, setIsCaptchaError] = useState(false); //show error when enter captcha invalid
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [tab, setTab] = useState(0);
  const tabLocal = Number(localStorage.getItem("fogotPass"));
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [search, setSearch] = useState({
    email: "",
    name: "",
    page: "",
    page_size: "",
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleLoginSubmit = (values: User) => {
    dispatch(handleSetLoading());
    if (myCaptcha.toLowerCase() === rightCode) {
      dispatch(
        handleLogin({
          username: values.email, //api login define username=email when login
          password: values.password,
        })
      );
      reset();
    } else {
      setIsCaptchaError(true);
      setMyCaptcha("");
    }
    if (canvasRef.current) {
      setRightCode(getImgValiCode(canvasRef.current));
    }
    setMyCaptcha("");
  };

  useEffect(() => {
    if (accessToken) {
      if (userData?.userData?.is_superuser === true) {
        navigate("/accountsettings");
      } else {
        navigate("/accountsettings");
      }
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    if (canvasRef.current) {
      setRightCode(getImgValiCode(canvasRef.current));
    }
  }, []);

  const handleReloadCaptCha = () => {
    if (canvasRef.current) {
      setRightCode(getImgValiCode(canvasRef.current));
    }
  };

  const handleCaptchaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMyCaptcha(e.target.value);
    setIsCaptchaError(false);
  };

  useEffect(() => {
    if (failLogin > 2) {
      handleReloadCaptCha();
    }
  }, [failLogin]);

  /* <ReCAPTCHA
			// sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
			sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
			onChange={onChange}
		  /> */

  return (
    <>
      <Helmet>
        <title>
			Login
        </title>
        <meta name="description" content="Login" />
      </Helmet>
      <AuthPageLayout>
        {tab === 0 ? (
          <AuthFormLayout
            title={"Sign in to your account"}
            // onSubmit={handleSubmit(handleLoginSubmit)}
          >
            <TextField
              {...register("email")}
              helperText={errors.email?.message}
              error={isSubmitted && !_.isEmpty(errors.email?.message)}
              fullWidth
              variant="outlined"
              placeholder={"Enter your email address"}
              label={"Email address"}
              style={{ marginBottom: 27 }}
            />
            {/*<Input placeholder="Email address" {...register("email")} />*/}
            {/*<Error>{errors.email?.message}</Error>*/}
            {/*<Input*/}
            {/*  placeholder="Password"*/}
            {/*  {...register("password")}*/}
            {/*  type="password"*/}
            {/*/>*/}
            {/*<Error>{errors.password?.message}</Error>*/}

            <TextField
              {...register("password")}
              // required
              helperText={errors.password?.message}
              error={isSubmitted && !_.isEmpty(errors.password?.message)}
              fullWidth
              type={values.showPassword ? "text" : "password"}
              placeholder="Enter your password"
              label={"Password"}
              style={{ marginBottom: 27 }}
              InputProps={{
                disableUnderline: true,

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {!values.showPassword && <EyeIcon />}
                      {values.showPassword && <EyeCloseIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {failLogin > 2 && (
              // <CaptchaStyled>
              // 	<div className="captcha-form-wrap">
              // 		<div className="captcha-form">
              // 			<Captcha ref={canvasRef} isCaptchaError={isCaptchaError} />
              // 			<ReloadOutlined
              // 				className="reload-icon"
              // 				onClick={handleReloadCaptCha}
              // 			/>
              // 			<div className="captcha-input-wrap">
              // 				<input
              // 					type="text"
              // 					className="captcha-input"
              // 					placeholder="Enter captcha code"
              // 					onChange={handleCaptchaChange}
              // 					value={myCaptcha}
              // 				/>
              // 				{isCaptchaError && (
              // 					<p className="error">Captcha is invalid.</p>
              // 				)}
              // 			</div>
              // 		</div>
              // 		<p className="captcha-desc">
              // 			The picture contains 6 characters
              // 		</p>
              // 	</div>
              // </CaptchaStyled>
              <CaptchaStyled>
                <div className="captcha-form-wrap">
                  <div className="captcha-form">
                    <Captcha ref={canvasRef} isCaptchaError={isCaptchaError} />
                    <ReloadOutlinedStyled
                      className="reload-icon"
                      onClick={handleReloadCaptCha}
                    />
                    <div className="captcha-input-wrap">
                      <CapchaTextField
                        label="Enter Captcha Code"
                        onChange={handleCaptchaChange}
                        value={myCaptcha}
                        fullWidth
                      />
                      {isCaptchaError && (
                        <p className="error">captcha is invalid</p>
                      )}
                    </div>
                  </div>
                  <TextBottom>The picture contains 6 characters</TextBottom>
                </div>
              </CaptchaStyled>
            )}
            {/*<Grid>*/}
            {/*  <SubmitButton style={{ marginBottom: 20, backgroundColor:"#51B380" }}>Sign in</SubmitButton>*/}
            {/*<SignInButton style={{ marginBottom: 20, backgroundColor:"#51B380" }}>Sign in</SignInButton>*/}
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              style={{ marginBottom: 20, boxShadow: "none", height: "48px" }}
              onClick={handleSubmit(handleLoginSubmit)}
            >
              Sign in
            </Button>
            {/*</Grid>*/}
            {/*<SignInButton>Sign in</SignInButton>*/}
            {/*<div className="link-btn">*/}
            {/*  <Link to="/register">*/}
            {/*    <Navigation>Don't have an account. Register here</Navigation>*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <Grid style={{ marginTop: 15 }}>
              <Link style={{ color: "#536283" }} underline="none">
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item>
                    <LockOutlinedIcon
                      style={{
                        height: "18px",
                        width: "18px",
                        marginTop: "3px",
                        marginRight: "3px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      onClick={() => setTab(1)}
                      style={{ fontSize: "1rem" }}
                    >
                      Forgot password?
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          </AuthFormLayout>
        ) : (
          <ForgotPassword handleChangeTab={setTab} changeForm={1} />
        )}
      </AuthPageLayout>
    </>
  );
}
