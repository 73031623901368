import React, { useEffect } from "react";
import { LogoLight } from "../../../components/Icon";
import { Grid, styled, Typography } from "@mui/material";
import { AuthPage } from "../Auth";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

const Container = styled(Grid)({
  margin: "122px auto",
  maxWidth: 630,
  maxHeight: 300,
  textAlign: "center",
});

const Title = styled(Typography)({
  fontSize: 36,
  fontWeight: 700,
  color: "blue",
  background: "linear-gradient(90deg, #51B380 2.99%, #067B3E 96.45%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  lineHeight: "48px",
  textTransform: "capitalize",
});

const Success = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 5000);
  }, []);

  return (
    <>
      {process.env.REACT_APP_WEB_URL === "https://admin.verifytrusted.com" && (
        <Helmet>
          <script>
            {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '926842531820237');
      fbq('track', 'PageView');
    `}
          </script>
          <noscript>
            {`<img
      height="1"
      width="1"
      style= "display:none"
      src="https://www.facebook.com/tr?id=926842531820237&ev=PageView&noscript=1"
      alt=""
    />`}
          </noscript>
        </Helmet>
      )}

      <AuthPage
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100vh",
          width: "100%",
        }}
      >
        <Container container>
          <Grid item xs={12} style={{ marginBottom: 67 }}>
            <LogoLight style={{ width: 141, height: 94 }} />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 50 }}>
            <Title>Thanks for joining us!!!</Title>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 58 }}>
            <Typography style={{ fontSize: 18, fontWeight: 400 }}>
              Now you can take control of your reviews and trust. Check your
              email for confirmation from us and more info on how to update your
              profile and maximise trust in your company.
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color={"green"}
              style={{ fontSize: 17, fontWeight: 700 }}
            >
              *please check junk/spam folder if email does not appear
            </Typography>
          </Grid>
        </Container>
      </AuthPage>
    </>
  );
};

export default Success;
