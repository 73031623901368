const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="Property 1=Dashboard">
        <path
          id="icon/action/dashboard"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H11V13H3V3ZM21 3H13V9H21V3ZM9 11V5H5V11H9ZM19 7V5H15V7H19ZM19 13V19H15V13H19ZM9 19V17H5V19H9ZM21 11H13V21H21V11ZM3 15H11V21H3V15Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;