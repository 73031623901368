const WidgetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="Property 1=Widget">
        <path
          id="icon/action/view_quilt"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 5.5V18.5H20.5V5.5H3.5ZM5.5 16.5V7.5H8.5V16.5H5.5ZM10.5 13V16.5H13.5V13H10.5ZM18.5 16.5H15.5V13H18.5V16.5ZM10.5 7.5V11H18.5V7.5H10.5Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
};

export default WidgetIcon;
