import {
  AccountIcon,
  DashboardIcon,
  GlobeIcon,
  LockIcon,
  ProfileIcon,
  ReviewIcon,
  WidgetIcon,
  ChangeLogIcon,
} from "../components/Icon";
import PaymentHistoryIcon from "../components/Icon/PaymentHistoryIcon";

export interface Menu {
  name: string;
  icon: any;
  href: string | null;
  subMenu?: Array<Menu>;
}

export const NavMenuAdmin: Array<Menu> = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    href: "/dashboard",
  },
  {
    name: "User Management",
    icon: <ProfileIcon />,
    href: "/usermanagement",
  },
  {
    name: "Company Profile",
    icon: <ProfileIcon />,
    href: "/profiles",
  },
  {
    name: "Change Log",
    icon: <ChangeLogIcon />,
    href: "/changelog",
  },
  {
    name: "Payment Management",
    icon: <PaymentHistoryIcon />,
    href: "/paymenthistory",
  },
  {
    name: "System Review Sources",
    icon: <GlobeIcon />,
    href: "/reviewsource",
  },
  {
    name: "Account Setting",
    icon: <AccountIcon />,
    href: "/accountsettings",
  },
  {
    name: "Change Password",
    icon: <LockIcon />,
    href: "/changepassword",
  },
];
export const NavMenuUser: Array<Menu> = [
  {
    name: "Company Profile",
    icon: <ProfileIcon />,
    href: "/profiles",
  },
  {
    name: "Review Sources",
    icon: <GlobeIcon />,
    href: "/userreviewsource",
  },
  {
    name: "Widget",
    icon: <WidgetIcon />,
    href: "/widget",
  },
  {
    name: "Full Page Widget",
    icon: <WidgetIcon />,
    href: "/new-widget",
  },
  {
    name: "Multi Branch Widget",
    icon: <WidgetIcon />,
    href: "/multi-branch-widget",
  },
  {
    name: "Get Trustseals",
    icon: <WidgetIcon />,
    href: "/trustseal",
  },
  {
    name: "Account Setting",
    icon: <AccountIcon />,
    href: "/accountsettings",
  },
  {
    name: "Change Password",
    icon: <LockIcon />,
    href: "/changepassword",
  },
];
