import { message } from "antd";
import { Dispatch } from "redux";
import {
  ACCESS_TOKEN,
  FAIL_LOGIN,
  REFRESH_TOKEN,
  USER,
  WRONG_NUMBER_LOGIN,
  INFO_FB,
} from "../../../constant";
import { errorMessages } from "../../../constant/errorMessages";
import {
  ChangePasswordRequest,
  ConfirmResetPasswordRequest,
  ValidateTokenRequest,
} from "../../../interface";
import { Cookie } from "../../../interface/Cookie";
import { getCookies } from "../../../utility/common/getCookies";
import {
  ACCOUNT_NOT_FOUND,
  CHANGE_PASSWORD,
  CONFIRM_RESET_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  LOGIN,
  LOGIN_WRONG_INFO,
  LOGOUT,
  LOADING,
  REGISTER,
  UPDATE_USERDATA,
  REGISTER_ERROR,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_INVALID,
  UPDATE_ACCOUNT_SETTING,
  CLEAR_PROFILE,
  REGISTER_ERROR_IP
} from "../../actionType";
import {
  changePassword,
  checkRegister,
  confirmResetPassword,
  deactive,
  getUserData,
  login,
  logout,
  register,
  resetPassword,
  updateAccountSetting,
  validateToken,
} from "../api/auth";
//Set loadding is true
export const handleSetLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LOADING });
  };
};
//** Handle login */
export const handleLogin = (data: any) => {
  
  return async (dispatch: Dispatch) => {
    try {
      const loginRes = await login(data);
      let userData;
      if (loginRes?.data?.access) {
        // login success
        const { REACT_APP_WEB_DOMAIN } = process.env;
        const now = new Date();
        document.cookie = `${REFRESH_TOKEN}=${loginRes.data.refresh}; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
        document.cookie = `${ACCESS_TOKEN}=${loginRes.data.access}; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;

        localStorage.removeItem(FAIL_LOGIN);

        const accessToken = loginRes.data.access;
        if (accessToken) {
          const userDataRes = await getUserData();
          userData = userDataRes.data;
          // localStorage.setItem(USER, JSON.stringify(userData));
          document.cookie = `${USER}=${JSON.stringify(userData)}; domain=${
            REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
          }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
        }
        dispatch({
          type: LOGIN,
          payload: { login: loginRes.data, userData: userData },
        });
        message.info("Login successfully");
      } else {
        // login failure
        if (typeof loginRes.data.fail_login === "number") {
          if (loginRes.data.detail === "MSG018") {
            message.error(` Email or password you entered is incorrect.`);
          }
          // account not found
          // message.error(`${loginRes.data.detail}`);
          dispatch({ type: ACCOUNT_NOT_FOUND });
        } else {
          // wrong email or password
          localStorage.setItem(FAIL_LOGIN, loginRes.data.fail_login);
          if (loginRes.data.detail === "MSG018") {
            message.error(` Email or password you entered is incorrect.`);
          }
          dispatch({
            type: LOGIN_WRONG_INFO,
            payload: { failLogin: loginRes.data.fail_login },
          });
        }
      }
    } catch (error: any) {
      message.error(`Something is wrong!`);
    }
  };
};

//** Handle logout */
export const handleLogout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAR_PROFILE });
    const cookieObj: Cookie = getCookies();
    const { REACT_APP_WEB_DOMAIN } = process.env;
    const now = new Date();
    document.cookie = `${USER}=; domain=${
      REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
    }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
    document.cookie = `${ACCESS_TOKEN}=; domain=${
      REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
    }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
    document.cookie = `${REFRESH_TOKEN}=; domain=${
      REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
    }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
    document.cookie = `${INFO_FB}=; domain=${
      REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
    }; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    localStorage.setItem("page_id", "");

    if (cookieObj?.refreshToken) {
      await logout(cookieObj.refreshToken);
    }
  };
};

// handle register

export const handleRegister = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await register(data);
      dispatch({ type: REGISTER, payload: res.data });
      message.info("Register successfully");
      try {
        const loginRes = await login({
              username: data.email,
              password: data.password,
          });
        let userData : any;
        if (loginRes?.data?.access) {
          // login success
          const { REACT_APP_WEB_DOMAIN } = process.env;
          const now = new Date();
          document.cookie = `${REFRESH_TOKEN}=${loginRes.data.refresh}; domain=${
            REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
          }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
          document.cookie = `${ACCESS_TOKEN}=${loginRes.data.access}; domain=${
            REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
          }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
  
          localStorage.removeItem(FAIL_LOGIN);
          const accessToken = loginRes.data.access;
          if (accessToken) {
            const userDataRes = await getUserData();
            userData = userDataRes.data;
            // localStorage.setItem(USER, JSON.stringify(userData));
            document.cookie = `${USER}=${JSON.stringify(userData)}; domain=${
              REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
            }; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
          }
          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_WEB_CLIENT_URL}/payment?id=${userData.company}&open=${true}`;
          }, 2000);
          // dispatch({
          //   type: LOGIN,
          //   payload: { login: loginRes.data, userData: userData },
          // });
          // message.info("Login successfully");
        } else {
          // login failure
          if (typeof loginRes.data.fail_login === "number") {
            if (loginRes.data.detail === "MSG018") {
              message.error(` Email or password you entered is incorrect.`);
            }
            // account not found
            // message.error(`${loginRes.data.detail}`);
          } else {
            // wrong email or password
            localStorage.setItem(FAIL_LOGIN, loginRes.data.fail_login);
            if (loginRes.data.detail === "MSG018") {
              message.error(` Email or password you entered is incorrect.`);
            }
           
          }
        }
      } catch (error: any) {
        message.error(`Something is wrong!`);
      }


    } catch (error: any) {
      const errorMessage: Array<string> = [];
      if (error.company) {
        errorMessage.push("The company profile has already claimed!");
      }
      if (error.email) {
        const codeError = error.email[0];
        if (codeError === "MSG017") {
          errorMessage.push(errorMessages.MSG017);
        }
      }
      errorMessage.map((item) => message.error(item, 3));
      dispatch({ type: REGISTER_ERROR, payload: errorMessage });
      if (error?.error?.ip === "MSG018") {
      dispatch({ type: REGISTER_ERROR_IP, payload: error?.error?.message ?? ""});
      }
    }
  };
};

export const handleRequestResetPassword = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await resetPassword(email);
      if (res?.status === 200) {
        if (res?.data?.status === "OK") {
          dispatch({ type: FORGOT_PASSWORD, payload: res.data.status });
          message.success("Email sent!");
        }
      }
    } catch (error: any) {
      // const errorMessage = error.email[0];
      message.error(`Something is wrong!`);
      // dispatch({ type: FORGOT_PASSWORD_FAIL, payload: errorMessage });
    }
  };
};

export const handleConfirmResetPassword = (
  data: ConfirmResetPasswordRequest
) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await confirmResetPassword(data);
      if (res?.status === 200) {
        if (res?.data?.status === "OK") {
          dispatch({ type: CONFIRM_RESET_PASSWORD });
          message.success("Success");
          return;
        }
      }
      throw new Error("Something is wrong");
    } catch (error: any) {
      message.error(`Something is wrong!`);
    }
  };
};

export const handleValidateToken = (data: ValidateTokenRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await validateToken(data);
      if (res?.status === 200) {
        if (res?.data?.status === "OK") {
          dispatch({ type: VALIDATE_TOKEN });
          return;
        }
      }
      throw new Error("Something is wrong");
    } catch (error: any) {
      message.error(error || `Something is wrong!`);
      dispatch({ type: VALIDATE_TOKEN_INVALID });
    }
  };
};
export const handleUpdateAccountSetting = (id: number, formData: FormData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      await updateAccountSetting(id, formData);
      const userDataRes = await getUserData();
      dispatch({ type: REGISTER, payload: userDataRes.data });
      message.success("Update success");
    } catch (error: any) {
      dispatch({ type: UPDATE_ACCOUNT_SETTING });
      if (error?.profile?.photo) {
        message.error('Please, check the attached image')
      } else {
        message.error(`Something is wrong!`);
      }
    }
  };
};
export const handleChangePassword = (data: ChangePasswordRequest) => {
  return async (dispatch: Dispatch) => {
    try {
      let res = await changePassword(data);
      if (res?.status === 200) {
        message.success("Change password success, please login again");
        const cookieObj: Cookie = getCookies();
        const { REACT_APP_WEB_DOMAIN } = process.env;
        const now = new Date();
        document.cookie = `${USER}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
        document.cookie = `${ACCESS_TOKEN}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
        document.cookie = `${REFRESH_TOKEN}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;

        if (cookieObj?.refreshToken) {
          await logout(cookieObj.refreshToken);
        }
        dispatch({ type: LOGOUT });
      }
    } catch (error: any) {
      message.error(`Something is wrong!`);
    }
  };
};

export const handleCheckRegister = (token: any, navigate: any) => {
  return async (dispatch: Dispatch) => {
    try{
      let res = await checkRegister(token);
      if(res?.status === 200){
        if(res?.data?.is_register){
          navigate("/login");
        }else{
          navigate(`/resetpassword?token=${token}`)
        }
      }
    }catch(e: any){
      message.error(`Something is wrong!`);
    }
  }
}

//Account Setting

export const handleDeactiveAccount = () => {
  return async (dispatch: Dispatch) => {
    try{
      let res = await deactive();
      if(res?.status === 200){
        message.success("Deactive account success!");
        const cookieObj: Cookie = getCookies();
        const { REACT_APP_WEB_DOMAIN } = process.env;
        const now = new Date();
        document.cookie = `${USER}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
        document.cookie = `${ACCESS_TOKEN}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
        document.cookie = `${REFRESH_TOKEN}=; domain=${
          REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
        }; expires=${now.getTime() - 1 * 3600 * 1000}; path=/;`;
        if (cookieObj?.refreshToken) {
          await logout(cookieObj.refreshToken);
        }
        dispatch({ type: LOGOUT });
      }
    }catch(e: any){
      message.error(`Something is wrong!`)
    }
  }
}

export const handleGetUserData = () => {
	return async(dispatch: Dispatch) => {
		let userData;
		const { REACT_APP_WEB_DOMAIN } = process.env;
		const userDataRes = await getUserData();
		const now = new Date();
		userData = userDataRes?.data;
		// localStorage.setItem(USER, JSON.stringify(userData));
		document.cookie = `${USER}=${JSON.stringify(userData)}; domain=${
		  REACT_APP_WEB_DOMAIN === "localhost" ? "" : REACT_APP_WEB_DOMAIN
		}; expires=${now.getTime() + 1 * 3600 * 1000}; path=/;`;
		dispatch({
			type: UPDATE_USERDATA,
			payload: { userData: userData },
		  });
	}
}