import { lazy } from "react";
import { Login } from "../../views/Auth/Login";
import { Register } from "../../views/Auth/Register";

import PrivateRoute from "./PrivateRoute";
import Success from "../../views/Auth/Success";

const Home = lazy(() => import("../../views/Home"));
const ErrorPage = lazy(() => import("../../views/Error"));
const ResetPassword = lazy(() => import("../../views/Auth/ResetPassword"));
const ChangePassword = lazy(() => import("../../views/Auth/ChangePassword"));
const ProfileList = lazy(() => import("../../views/Profile/ProfileList"));
const UserManagement = lazy(() => import("../../views/UserManagement"));
const ChangeLog = lazy(() => import("../../views/ChangeLog"));
const Payment = lazy(() => import("../../views/PaymentHistory"));
const WidgetTrustSeal = lazy(() => import("../../views/WidgetTrustseal"));
const NewWidget = lazy(() => import("../../views/NewWidget"));
const MultiBranchWidget = lazy(() => import("../../views/MultiBranchWidget"));
const TrustSeal = lazy(() =>
  import("../../views/TrustSeal").then(({ TrustSeal }) => ({
    default: TrustSeal,
  }))
);
const AccountSetting = lazy(() => import("../../views/AccountSetting"));
const Profile = lazy(() => import("../../views/Profile/ProfilePage"));
const UserReviewResource = lazy(
  () => import("../../views/ReviewResource/UserReviewSource/")
);
const AdminReviewResource = lazy(
  () => import("../../views/ReviewResource/AdminReviewSource")
);
const ActiveAccount = lazy(() => import("../../views/Auth/ActiveAccount"));
const ReviewText = lazy(() => import("../../views/ReviewText"));
const CheckRegister = lazy(() => import("../../views/Auth/CheckRegister"));
const ChangeLogDetail = lazy(
  () => import("../../views/ChangeLog/ChangeLogDetail")
);

const Routes = () => [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/profiles/:id",
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/login",
    element: (
      <PrivateRoute loginOnly={true}>
        <Login />
      </PrivateRoute>
    ),
  },
  {
    path: "/register",
    element: (
      <PrivateRoute loginOnly={true}>
        <Register />
      </PrivateRoute>
    ),
  },
  {
    path: "/success",
    element: (
      <PrivateRoute>
        <Success />
      </PrivateRoute>
    ),
  },
  {
    path: "/resetpassword",
    element: (
      <PrivateRoute loginOnly={true}>
        <ResetPassword />
      </PrivateRoute>
    ),
  },
  {
    path: "/changepassword",
    element: (
      <PrivateRoute>
        <ChangePassword />
      </PrivateRoute>
    ),
  },
  {
    path: "/profiles",
    element: (
      <PrivateRoute>
        <ProfileList />
      </PrivateRoute>
    ),
  },
  {
    path: "/changelog",
    element: (
      <PrivateRoute>
        <ChangeLog />
      </PrivateRoute>
    ),
  },
  {
    path: "/paymenthistory",
    element: (
      <PrivateRoute>
        <Payment />
      </PrivateRoute>
    ),
  },
  {
    path: "/usermanagement",
    element: (
      <PrivateRoute adminOnly={true}>
        <UserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/accountsettings",
    element: (
      <PrivateRoute>
        <AccountSetting />
      </PrivateRoute>
    ),
  },
  {
    path: "/reviewsource",
    element: (
      <PrivateRoute adminOnly={true}>
        <AdminReviewResource />
      </PrivateRoute>
    ),
  },
  {
    path: "/widget",
    element: (
      <PrivateRoute>
        <WidgetTrustSeal />
      </PrivateRoute>
    ),
  },
  {
    path: "/new-widget",
    element: (
      <PrivateRoute>
        <NewWidget />
      </PrivateRoute>
    ),
  },
  {
    path: "/multi-branch-widget",
    element: (
      <PrivateRoute>
        <MultiBranchWidget />
      </PrivateRoute>
    ),
  },
  {
    path: "/trustseal",
    element: (
      <PrivateRoute>
        <TrustSeal />
      </PrivateRoute>
    ),
  },
  {
    path: "/userreviewsource",
    children: [
      {
        path: "",
        element: (
          <PrivateRoute>
            <UserReviewResource />
          </PrivateRoute>
        ),
      },
      {
        path: "/userreviewsource/:id",
        element: (
          <PrivateRoute adminOnly={true}>
            <UserReviewResource />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/reviewtext",
    children: [
      {
        path: "",
        element: (
          <PrivateRoute>
            <ReviewText />
          </PrivateRoute>
        ),
      },
      {
        path: "/reviewtext/:id",
        element: (
          <PrivateRoute adminOnly={true}>
            <ReviewText />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/active-user",
    element: (
      <PrivateRoute loginOnly={true}>
        <ActiveAccount />
      </PrivateRoute>
    ),
  },
  {
    path: "/changelog/:id",
    element: (
      <PrivateRoute>
        <ChangeLogDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/check-register",
    element: (
      <PrivateRoute loginOnly={true}>
        <CheckRegister />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
];

export { Routes };
