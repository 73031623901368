import { Link } from "react-router-dom";
import styled from "styled-components";
import mediaQueries from "../../utility/responsive";

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000030;
  height: 100%;
  z-index: 1;

  ${mediaQueries.nav} {
    justify-content: start;
  }
`;

const Copyright = styled.div`
  position: absolute;
  height: 16px;
  bottom: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: #979da0;

  ${mediaQueries.nav} {
    margin-top: 20vh;
    position: relative;
  }
`;

const Signup = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin-top: 48px;
  & span {
    font-weight: bold;
  }

  ${mediaQueries.nav} {
    margin-top: 32px;
  }
`;

const AuthPage = ({ children }: { children: JSX.Element }) => {
  return (
    <Layout>
      {children}
      <Link to="/register">
        <Signup>
          Don't have an account?&nbsp;
          <span>Signup Now</span>
        </Signup>
      </Link>
      <Copyright>
        © 2022 VerifyTrusted.&nbsp;{process.env.REACT_APP_VERSION_BUILD}
      </Copyright>
    </Layout>
  );
};

export default AuthPage;
