export const getImgValiCode = (canvasCurrent: HTMLCanvasElement) => {
    const showNum = [];
    const canvasWinth = 250;
    const canvasHeight = 60;
    const canvas: any = canvasCurrent;
    const context = canvas.getContext("2d");
    canvas.width = canvasWinth;
    canvas.height = canvasHeight;
    const sCode =
      "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,0,1,2,3,4,5,6,7,8,9";
    const saCode = sCode.split(",");
    const saCodeLen = saCode.length;
    for (let i = 0; i < 6; i++) {
      const sIndex = Math.floor(Math.random() * saCodeLen);
      const sDeg = (Math.random() * 30 * Math.PI) / 180;
      const cTxt = saCode[sIndex];
      showNum[i] = cTxt.toLowerCase();
      const x = 40 + i * 20;
      const y = 35 + Math.random() * 8;
      context.font = "bold 35px 微软雅黑";
      context.translate(x, y);
      context.rotate(sDeg);

      // context.fillStyle = randomColor();
      context.fillText(cTxt, 0, 0);

      context.rotate(-sDeg);
      context.translate(-x, -y);
    }
    for (let i = 0; i <= 5; i++) {
      // context.strokeStyle = randomColor();
      context.beginPath();
      context.moveTo(Math.random() * canvasWinth, Math.random() * canvasHeight);
      context.lineTo(Math.random() * canvasWinth, Math.random() * canvasHeight);
      context.stroke();
    }
    for (let i = 0; i < 30; i++) {
      // context.strokeStyle = randomColor();
      context.beginPath();
      const x = Math.random() * canvasWinth;
      const y = Math.random() * canvasHeight;
      context.moveTo(x, y);
      context.lineTo(x + 1, y + 1);
      context.stroke();
    }
    const rightCode = showNum.join("");
    return rightCode;
  };