import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface Props {
  isOpenModal: boolean;
  handleClose: () => void;
  ip: string;
  messageErrorIp: string;
}

const BtnContainer = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  marginTop: 20,
});
export const CancelBtn = styled("button")({
  width: "108px",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: "bold",
  height: "48px",
  marginLeft: "16px",
  borderRadius: "4px",
  border: "none",
  color: "#000030",
});
const Layout = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 508px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  fontsize: "16px";
`;

export default function DialogError(props: Props) {
  const { isOpenModal, handleClose, ip, messageErrorIp } = props;

  return (
    <Modal open={isOpenModal} sx={{ zIndex: 1 }}>
      <Layout>
        <div>
          {messageErrorIp ??
            `Your IP (${ip}) created at least 1 company. Please contact
          info@VerifyTrusted to validate you have permission to create this
          company`}
        </div>
        <BtnContainer>
          <CancelBtn color="primary" onClick={handleClose}>
            Close
          </CancelBtn>
        </BtnContainer>
      </Layout>
    </Modal>
  );
}
