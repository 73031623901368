import { StarRateOutlined } from "@mui/icons-material";
import { PayloadAction } from "@reduxjs/toolkit";
import { Seals } from "../../../interface/Seal";
import {
	GET_ALL_SEALS,
	CREATE_SEAL,
	GET_SEAL_WITH_COMPANYID
} from '../../actionType';

interface sealReducerType {
	sealsList: Array<any>;
	SealWithId: Seals;
	sealID: any
}

const initalState: sealReducerType = {
	sealsList: [],
	SealWithId: {
		id: "",
		seal_type: "TYPE_1",
		company: 0
	},
	sealID: null,
}

const sealsReducer = (state = initalState, action: PayloadAction<any>): sealReducerType => {
	switch (action.type) {
		case GET_ALL_SEALS:
			return {
				...state,
				sealsList: action.payload,
			}
		case CREATE_SEAL:
			return {
				...state,
				sealID: action.payload.id
			}
		case GET_SEAL_WITH_COMPANYID:
			return {
				...state,
				SealWithId:action.payload,
			}
		default:
			return state;
	}
}

export default sealsReducer;