import { SVGProps } from "react";
import { SvgProps } from "./types";

const EditIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="Property 1=Edit">
        <path
          id="icon/image/edit"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
          fill={props.fill || "#848687"}
        />
      </g>
    </svg>
  );
};

export default EditIcon;
