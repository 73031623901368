import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Collapse,
  ListItemButtonProps,
} from "@mui/material";
import { styled } from "@mui/system";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Menu } from "../../constant/NavMenu";
import { DownIcon, UpIcon, NavigationIcon } from "../Icon";

type CustomItemButtonProps = ListItemButtonProps & {
  isSub?: boolean;
  isActive?: boolean;
};

const CustomListButtom = styled((props: CustomItemButtonProps) => (
  <ListItemButton {...props} />
))`
  & .MuiTypography-root {
    font-weight: ${({ isSub }) => (isSub ? 400 : 600)};
    padding-left: ${({ isSub }) => (isSub ? 28 : 0)}px;
  }
  padding-left: 24px;
`;

const ListItemTextStyled = styled(ListItemText) <CustomItemButtonProps>`
  ${({ isActive }) => (isActive && 'color: #51B380')}
`;

const ListItemIconStyled = styled(ListItemIcon) <CustomItemButtonProps>`
  ${({ isActive }) => (isActive && 'filter: invert(63%) sepia(16%) saturate(1166%) hue-rotate(96deg) brightness(95%) contrast(88%);')}
`;

const MenuItem = ({
  menu,
  isSub = false,
  indexSelected,
  index,
  onClicked
}: {
  menu: Menu;
  isSub?: boolean;
  indexSelected: number;
  index: number;
  onClicked?: (index: number)=> void;
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (indexSelected === index) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

  }, [indexSelected, index]);

  //Expand submenu or redirect to url
  const handleClick = (url: string | null) => {
    if (url === null) {
      setOpen(!open);
    } else {
      navigate(url);
      if(onClicked){
        onClicked(index);
      }
    }
  };

  return (
    <>
      <CustomListButtom
        onClick={() => handleClick(menu?.href)}
        isSub={isSub}
        isActive={isSelected}
      >
        {menu?.icon && <ListItemIconStyled isActive={isSelected}>{menu.icon}</ListItemIconStyled>}
        <ListItemTextStyled isActive={isSelected}>{menu.name}</ListItemTextStyled>
        {isSelected && <NavigationIcon />}
        {menu?.href === null && (open ? <UpIcon /> : <DownIcon />)}
      </CustomListButtom>
      {menu?.subMenu && menu?.subMenu?.length > 0 && (
        <Collapse in={open} timeout={"auto"} unmountOnExit>
          <MenuList>
            {menu?.subMenu?.map((item, i) => (
              <MenuItem key={i} menu={item} isSub={true} indexSelected={indexSelected} index={index} />
            ))}
          </MenuList>
        </Collapse>
      )}
    </>
  );
};

export default MenuItem;
