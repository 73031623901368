import {PayloadAction} from "@reduxjs/toolkit";
import {GET_SOURCE_REVIEW} from "../../actionType";

interface sourceReviewReducerType {
    sourceReviewList: Array<any>;
}

const initialState: sourceReviewReducerType = {
    sourceReviewList: [],
}

const sourceReviewReducer = (state = initialState, action: PayloadAction<any>): sourceReviewReducerType => {
    switch (action.type) {
        case GET_SOURCE_REVIEW:
            return {
                ...state,
                sourceReviewList: action.payload,
            }
        default:
            return state;
    }
}

export default sourceReviewReducer;