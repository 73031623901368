const VerifyTrustStarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="222" height="223" viewBox="0 0 222 223" fill="none">
            <g opacity="0.07">
                <path
                    d="M115.258 2.61215C123.094 26.0897 130.503 49.7087 138.339 73.1863C139.051 75.3077 140.904 76.5806 143.183 76.5806C167.404 76.4392 188.348 76.4392 212.568 76.4392C213.851 76.4392 214.421 78.1364 213.281 78.8435C208.579 81.8136 205.587 84.925 200.743 87.4708C199.461 88.178 197.893 88.6023 196.469 88.6023C175.098 88.8851 155.578 88.6023 134.207 88.7437C132.07 88.7437 130.075 87.3294 129.363 85.3493C123.949 69.2262 118.962 52.9616 113.406 36.8385C112.978 35.707 111.269 35.707 110.841 36.8385C105.285 53.3859 100.013 69.9334 94.5992 86.4808C94.1718 87.7537 92.8895 88.7437 91.6073 88.7437C73.3705 88.7437 55.2762 88.6023 37.0394 88.7437C36.327 88.7437 36.0421 89.7337 36.612 90.158C51.4294 101.048 66.3892 111.231 80.9217 122.404C81.634 122.97 82.0615 123.96 81.7765 124.95C78.7845 134.426 74.7952 144.043 71.5183 153.519C71.0909 154.792 69.2387 154.509 69.0963 153.236C68.5264 145.599 69.0962 137.679 68.5263 130.042C68.3839 128.91 67.814 127.92 66.9591 127.213C45.018 111.373 23.2194 95.8152 1.42071 79.975C-0.00404203 78.9849 0.708326 76.722 2.5605 76.722C29.4882 76.5806 56.416 76.8635 83.2012 76.722C84.341 76.722 85.3384 76.0149 85.6233 75.0249C93.4594 50.9816 101.153 26.9383 109.132 2.89501C109.987 -0.21647 114.261 -0.21647 115.258 2.61215Z"
                    fill="#51B380"/>
                <path
                    d="M52.5691 216.88C64.1096 185.907 75.5076 155.075 87.0481 124.102C87.333 123.394 88.4728 123.536 88.4728 124.384L80.0668 176.431C79.7818 177.845 81.4916 178.835 82.6313 178.128C123.664 150.408 177.947 107.978 220.12 79.1265C220.974 78.5608 221.829 79.6923 221.117 80.3994C167.831 125.375 108.847 174.875 56.131 219.426C54.2788 220.558 51.8568 218.86 52.5691 216.88Z"
                    fill="#000030"/>
                <path
                    d="M190.912 219.143C182.934 195.1 170.396 158.752 162.56 134.709L151.732 143.619C155.151 153.944 162.845 176.431 166.264 186.756C166.549 187.746 165.409 188.594 164.554 188.029C155.721 181.806 137.769 169.077 129.078 162.854L117.395 172.613C137.627 187.039 166.407 207.687 186.638 222.255C188.775 223.811 191.767 221.689 190.912 219.143Z"
                    fill="#51B380"/>
            </g>
        </svg>
    )
}

export default VerifyTrustStarIcon;